import { BehaviorSubject } from "rxjs";
import { PageRoute } from "../types";
import { PageType } from "../constants";

export const hydrateNextPageToComplete = (
  currentTool: string,
  activeTool: string | null,
) => {
  const pageType = parseInt(sessionStorage.getItem("pageType"));
  const pageNumber = parseInt(sessionStorage.getItem("pageNumber"));

  if (!!pageType && activeTool === currentTool) {
    return new BehaviorSubject<PageRoute>({ pageType, pageNumber });
  } else {
    return new BehaviorSubject<PageRoute>({
      pageType: PageType.SURVEY,
      pageNumber: 1,
    });
  }
};
