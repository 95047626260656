import api from "./api";
import { PtaUser } from "../types";

type GetAuthenticatedUser = (authId: string) => Promise<PtaUser>;

export const getAuthenticatedUserFromAuthId: GetAuthenticatedUser = (
  authId,
) => {
  const parsedAuthId = authId.replace("auth0|", "");

  return api
    .get<PtaUser>(`/authenticated_users/${parsedAuthId}`)
    .then((resp) => resp.data)
    .catch((err) => err);
};
