import { FormApi } from "final-form";
import { Subject } from "rxjs";

import { FormValues } from "../types";

const buildUserUpdatesDecorator = (updates: Subject<FormValues>) => {
  const userUpdatesDecorator = (formApi: FormApi<FormValues>) => {
    return formApi.subscribe(
      ({ values }) => {
        updates.next(values);
      },
      { values: true },
    );
  };

  return userUpdatesDecorator;
};

export default buildUserUpdatesDecorator;
