import React from "react";
import "./LoadingSpinner.scss";
import useTimer from "../models/utils/useTimer";

type LoadingSpinnerPropsType = {
  delay?: number;
};

const LoadingSpinner: React.FC<LoadingSpinnerPropsType> = ({ delay = 350 }) => {
  const isVisible = useTimer(delay);

  return (
    <div className="loading-spinner" data-testid="loading-spinner">
      {isVisible ? (
        <div className="sk-circle">
          <div className="sk-circle1 sk-child"></div>
          <div className="sk-circle2 sk-child"></div>
          <div className="sk-circle3 sk-child"></div>
          <div className="sk-circle4 sk-child"></div>
          <div className="sk-circle5 sk-child"></div>
          <div className="sk-circle6 sk-child"></div>
          <div className="sk-circle7 sk-child"></div>
          <div className="sk-circle8 sk-child"></div>
          <div className="sk-circle9 sk-child"></div>
          <div className="sk-circle10 sk-child"></div>
          <div className="sk-circle11 sk-child"></div>
          <div className="sk-circle12 sk-child"></div>
        </div>
      ) : null}
    </div>
  );
};

export default LoadingSpinner;
