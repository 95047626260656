import React, { useState } from "react";

import { usePTAUser } from "../../models/auth/usePTAUser";

import NavMenu from "./NavMenu";

const UserNav = () => {
  const user = usePTAUser();

  const userFullName = user
    ? `${user.first_name || ""} ${user.last_name || ""}`
    : "";
  const [showNavMenu, setShowNavMenu] = useState(false);

  const toggleNavMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowNavMenu(!showNavMenu);
  };

  const isAdmin = !!user?.admin;

  return (
    <div className="user-nav">
      <span className="user-name">{userFullName}</span>

      <nav>
        {user && (
          <>
            <div
              data-testid="navbar-menu"
              className="menu"
              onClick={(e) => toggleNavMenu(e)}
            />

            {showNavMenu && (
              <NavMenu
                isAdmin={isAdmin}
                close={() => {
                  setShowNavMenu(false);
                }}
              />
            )}
          </>
        )}
      </nav>
    </div>
  );
};

export default UserNav;
