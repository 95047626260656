import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import currentToken from "./currentToken";

export default function useLogout() {
  const { logout } = useAuth0();

  return useCallback(() => {
    currentToken.next(null);
    sessionStorage.clear();
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);
}
