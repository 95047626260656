import { stripCommas } from "../../tools/utils/pdfHelpers";
import { Field, FormValues } from "../../types";

const validateNegativeCurrency = (
  formValues: FormValues,
  visibleFields: Field[],
) => {
  return visibleFields.reduce((errors, field) => {
    if (field.restrictNegative && formValues[field.name]) {
      const currencyValue = stripCommas(formValues[field.name]);
      if (parseFloat(currencyValue) < 0) {
        errors[field.name] = "no-negative-currency";
      }
    }
    return errors;
  }, {});
};

export default validateNegativeCurrency;
