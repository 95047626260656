import { FormElement, FormValues, HybridFormElement } from "../../types";
const fieldProperty = "field";
const errorMessage = "invalid-id";
const validateMask = (
  formValues: FormValues,
  fields: string[],
  formElements: FormElement[] | HybridFormElement[],
) => {
  return fields.reduce((errors, fieldName) => {
    const element = formElements.findIndex((ele) => ele.name === fieldName);
    const { validFormats } = formElements[element][fieldProperty];
    const value = formValues[fieldName];
    const regEx = new RegExp(validFormats);
    if (value && !regEx.test(value)) {
      errors[fieldName] = errorMessage;
    }
    return errors;
  }, {});
};
export default validateMask;
