import { BehaviorSubject } from "rxjs";
import { Token } from "../../types";
import useObservable from "../utils/useObservable";

export const tokenKey = "auth-token";
export const tokenTypeKey = "auth-token-type";
const tokenStr = sessionStorage.getItem(tokenKey);
const tokenTypeStr = sessionStorage.getItem(tokenTypeKey);
let token: Token | null = null;

if (tokenStr && tokenTypeStr) {
  try {
    const tokenValue = JSON.parse(tokenStr);
    const tokenType = JSON.parse(tokenTypeStr);
    if (typeof tokenValue === "string" && tokenType === "auth0") {
      token = {
        value: tokenValue,
        tokenType: tokenType,
      };
    }
  } catch (error) {
    console.error("Token error", error);
  }
}

const currentToken = new BehaviorSubject<Token | null>(token);

currentToken.subscribe((token) => {
  if (token) {
    sessionStorage.setItem(tokenKey, JSON.stringify(token.value));
    sessionStorage.setItem(tokenTypeKey, JSON.stringify(token.tokenType));
  } else {
    sessionStorage.removeItem(tokenKey);
    sessionStorage.removeItem(tokenTypeKey);
  }
});

export const useCurrentToken = () => useObservable(currentToken);

export default currentToken;
