import React from "react";
import { Link } from "react-router-dom";
import "./NavLink.scss";

interface Props {
  linkText: string;
  to?: string;
  href?: string;
  callback?: () => void;
}

const NavLink: React.FC<Props> = ({ to, linkText, callback, href }) => {
  const currentLocation = window.location;

  return (
    <div className="NavLink">
      {to && (
        <Link
          className={`NavLink + ${
            currentLocation.pathname === to ? " current-location" : ""
          }`}
          to={to || "#"}
        >
          {linkText}
        </Link>
      )}

      {href && (
        <a
          className={`NavLink + ${
            currentLocation.href === href ? " current-location" : ""
          }`}
          href={href}
          target="_blank"
        >
          {linkText}
        </a>
      )}

      {callback && <button onClick={callback}>{linkText}</button>}
    </div>
  );
};

export default NavLink;
