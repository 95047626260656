import clientConsentPdfUrl from "../../../pdfs/Client Consent Form.pdf";
import { booleanAsYesNo } from "../utils/pdfHelpers";
import { SurveyInputValues } from "./types";

const getClientConsentPdfFields = (values: SurveyInputValues) => {
  const clientConsentFields: Record<string, string> = {};
  clientConsentFields.Name = values["survey-1-client-name"] || "";
  clientConsentFields["Check Box"] = booleanAsYesNo(
    values["survey-2-agree-to-share"],
  );
  clientConsentFields["Send Info Check Box"] = booleanAsYesNo(
    values["survey-3-allow-to-send-info"],
  );
  clientConsentFields.Date = values["survey-6-client-consent-date"] || "";
  clientConsentFields["Applicant Email"] =
    values["survey-4-applicant-email"] || "";
  clientConsentFields["Co-applicant Email"] =
    values["survey-5-co-applicant-email"] || "";

  return { url: clientConsentPdfUrl, fields: clientConsentFields };
};

export default getClientConsentPdfFields;
