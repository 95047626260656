import { combineLatest, Observable } from "rxjs";
import { map, share } from "rxjs/operators";

import { FormValues } from "../types";
import { shouldLogFormValues } from "./conditionalLogging";

const buildFormValues = (
  userUpdatesObs: Observable<FormValues>,
  calculatedValuesObs: Observable<FormValues>,
) =>
  combineLatest([userUpdatesObs, calculatedValuesObs]).pipe(
    map(([userUpdatesValues, calculatedValues]) => {
      const formValues = {
        ...userUpdatesValues,
        ...calculatedValues,
      };
      if (shouldLogFormValues) {
        console.info("Form Values:", formValues);
      }
      return formValues;
    }),
    share(),
  );

export default buildFormValues;
