import { removeSpaces } from "../../tools/utils/pdfHelpers";
import { FormValues, FormElement } from "../../types";

const validYear = /^\d{4}$/;
const MINIMUM_AGE = 18;
const MAXIMUM_AGE = 120;
const CURRENT_YEAR = new Date().getFullYear();
const minimumBirthDate = new Date(CURRENT_YEAR - MAXIMUM_AGE, 0, 1);
const maximumBirthDate = new Date(CURRENT_YEAR - MINIMUM_AGE, 0, 1);

export const birthYearIsValid = (dateString: string) => {
  // Remove spaces before parsing to avoid error in Safari
  const birthDate = new Date(removeSpaces(dateString));

  return dateHasValidBirthYear(birthDate);
};

const dateHasValidBirthYear = (birthYearDate: Date): boolean => {
  const isBirthYearAllowed =
    birthYearDate >= minimumBirthDate && birthYearDate <= maximumBirthDate;

  return isBirthYearAllowed;
};

export const validateSurveyBirthYear = (
  formValues: FormValues,
  fields: string[],
  formElements: FormElement<Record<string, any>>[],
): Record<string, string | [string, object]> => {
  return fields.reduce((errors, fieldName) => {
    const birthYearField = formElements.find((ele) => ele.name === fieldName);
    const { name, field } = birthYearField!;

    if (field?.validateBirthYear) {
      const surveyBirthYear = formValues[name];

      if (!validYear.test(surveyBirthYear)) {
        errors[name] = ["invalid-birthyear"];
      }

      const birthYearToDate = new Date(+surveyBirthYear, 0, 1);
      if (!dateHasValidBirthYear(birthYearToDate)) {
        errors[name] = ["invalid-birthyear"];
      }
    }
    return errors;
  }, {});
};
