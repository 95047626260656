import { Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import memoize from "../util/memoize";

import { ToolDescription } from "../types";
import currentToolName from "./currentToolName";

type ToolModuleType = {
  default: () => Promise<ToolDescription>;
};

type LazyToolGetter = () => Promise<ToolModuleType>;

const getAZ2021: LazyToolGetter = memoize(
  () => import("../tools/az-2021/getTool"),
);
const getCO2021: LazyToolGetter = memoize(
  () => import("../tools/co-2021/getTool"),
);
const getCO2022: LazyToolGetter = memoize(
  () => import("../tools/co-2022/getTool"),
);
const getCO2023: LazyToolGetter = memoize(
  () => import("../tools/co-2023/getTool"),
);
const getDemographics: LazyToolGetter = memoize(
  () => import("../tools/demographics/getTool"),
);
const getFL2021: LazyToolGetter = memoize(
  () => import("../tools/fl-2021/getTool"),
);
const getIL2023: LazyToolGetter = memoize(
  () => import("../tools/il-2023/getTool"),
);
const getILCOE: LazyToolGetter = memoize(
  () => import("../tools/ilcoe/getTool"),
);
const getKS2021: LazyToolGetter = memoize(
  () => import("../tools/ks-2021/getTool"),
);
const getMDH2022: LazyToolGetter = memoize(
  () => import("../tools/mdh-2022/getTool"),
);
const getMDR2022: LazyToolGetter = memoize(
  () => import("../tools/mdr-2022/getTool"),
);
const getMI2021: LazyToolGetter = memoize(
  () => import("../tools/mi-2021/getTool"),
);
const getMI2022: LazyToolGetter = memoize(
  () => import("../tools/mi-2022/getTool"),
);
const getMI2023: LazyToolGetter = memoize(
  () => import("../tools/mi-2023/getTool"),
);
const getMIHC2022: LazyToolGetter = memoize(
  () => import("../tools/mihc-2022/getTool"),
);
const getMIHC2023: LazyToolGetter = memoize(
  () => import("../tools/mihc-2023/getTool"),
);
const getMN2022: LazyToolGetter = memoize(
  () => import("../tools/mn-2022/getTool"),
);
const getMN2023: LazyToolGetter = memoize(
  () => import("../tools/mn-2023/getTool"),
);
const getMO2021: LazyToolGetter = memoize(
  () => import("../tools/mo-2021/getTool"),
);
const getMO2022: LazyToolGetter = memoize(
  () => import("../tools/mo-2022/getTool"),
);
const getMO2023: LazyToolGetter = memoize(
  () => import("../tools/mo-2023/getTool"),
);
const getNC2023: LazyToolGetter = memoize(
  () => import("../tools/nc-2023/getTool"),
);
const getNH2023: LazyToolGetter = memoize(
  () => import("../tools/nh-2023/getTool"),
);
const getWDCH2022: LazyToolGetter = memoize(
  () => import("../tools/wdch-2022/getTool"),
);

// This is done so that we can implement code splitting
// When a user selects a tool name, we want the toolDescriptionManager,
// which is an observable, to emit the new toolName immediately, not to
// wait until the code has finished downloading.
//
// This is what the helper function is used for.  It accepts an initial value
// of a certain generic type T, and a promise which also resolves to type T
//
// The observable immediately emits the initial value.  When the promise resolves
// the observable emits the resolved value of the promise
function fromPromiseWithInitial<T>(
  initial: T,
  promise: Promise<T>,
): Observable<T> {
  return new Observable((observer) => {
    // Emit the initial value immediately
    observer.next(initial);

    promise.then(
      (value) => {
        observer.next(value);
        observer.complete();
      },
      (error) => {
        observer.error(error);
      },
    );
  });
}

function toolObservable(dynamic_module: LazyToolGetter, toolName: string) {
  const toolDescriptionPromise = dynamic_module().then((mod) => mod.default());
  return fromPromiseWithInitial<Partial<ToolDescription>>(
    { toolName, pending: true },
    toolDescriptionPromise,
  );
}

const toolDescriptionManager: Observable<Partial<ToolDescription>> =
  currentToolName.pipe(
    mergeMap((toolName) => {
      switch (toolName) {
        case "az":
        case "az-2021":
          return toolObservable(getAZ2021, "az-2021");
        case "co":
        case "co-2023":
          return toolObservable(getCO2023, "co-2023");
        case "co-2022":
          return toolObservable(getCO2022, "co-2022");
        case "co-2021":
          return toolObservable(getCO2021, "co-2021");
        case "demographics":
          return toolObservable(getDemographics, "demographics");
        case "fl":
        case "fl-2021":
          return toolObservable(getFL2021, "fl-2021");
        case "il":
        case "ilcoe":
          return toolObservable(getILCOE, "ilcoe");
        case "il-2023":
          return toolObservable(getIL2023, "il-2023");
        case "ks":
        case "ks-2021":
          return toolObservable(getKS2021, "ks-2021");
        case "mdh":
        case "mdh-2022":
          return toolObservable(getMDH2022, "mdh-2022");
        case "mdr":
        case "mdr-2022":
          return toolObservable(getMDR2022, "mdr-2022");
        case "mi":
        case "mi-2023":
          return toolObservable(getMI2023, "mi-2023");
        case "mi-2022":
          return toolObservable(getMI2022, "mi-2022");
        case "mi-2021":
          return toolObservable(getMI2021, "mi-2021");
        case "mihc":
        case "mihc-2022":
          return toolObservable(getMIHC2022, "mihc-2022");
        case "mihc-2023":
          return toolObservable(getMIHC2023, "mihc-2023");
        case "mn":
        case "mn-2023":
          return toolObservable(getMN2023, "mn-2023");
        case "mn-2022":
          return toolObservable(getMN2022, "mn-2022");
        case "mo":
        case "mo-2023":
          return toolObservable(getMO2023, "mo-2023");
        case "mo-2022":
          return toolObservable(getMO2022, "mo-2022");
        case "mo-2021":
          return toolObservable(getMO2021, "mo-2021");
        case "nc":
        case "nc-2023":
          return toolObservable(getNC2023, "nc-2023");
        case "nh":
        case "nh-2022":
          return toolObservable(getNH2022, "nh-2022");
        case "nh-2023":
          return toolObservable(getNH2023, "nh-2023");
        case "wdc":
        case "wdch-2022":
          return toolObservable(getWDCH2022, "wdhc-2022");
        case "":
          return of({});
        default:
          return of({});
      }
    }),
  );

export default toolDescriptionManager;
