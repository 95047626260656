import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";

import { AppRoutes } from "./Routes";
import { getAuthenticatedUserFromAuthId } from "./api/getAuthenticatedUserFromAuthId";
import { appConfig } from "./appConfig";
import InactivityManager from "./components/InactivityManager";
import LoginPage from "./components/pages/LoginPage";
import { PTAUserContext } from "./components/contexts/PTAUserContext";
import currentToken, { useCurrentToken } from "./models/auth/currentToken";
import useLogout from "./models/auth/useLogout";
import useToolNameFromUrlListener from "./models/useToolNameFromUrlListener";
import { PtaUser } from "./types";
import { useLocation, useNavigate } from "react-router-dom";

const AUTH0 = "auth0";

export const RoutesWrapper = () => {
  const logoutSession = useLogout();
  const token = useCurrentToken();
  const {
    isAuthenticated,
    getAccessTokenSilently,
    user: auth0User,
  } = useAuth0();
  const { authRequired } = appConfig;

  const [user, setUser] = useState<PtaUser | null>(null);

  useEffect(() => {
    if (isAuthenticated && !token) {
      getAccessTokenSilently().then((accessToken) => {
        currentToken.next({
          value: accessToken,
          tokenType: AUTH0,
        });
      });
    }
  }, [token, isAuthenticated]);

  useEffect(() => {
    if (auth0User?.sub && token) {
      getAuthenticatedUserFromAuthId(auth0User.sub).then((user) => {
        setUser(user);
      });
    }
  }, [auth0User, token]);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Resolves issue where auth0 returnTo redirect was not picked up by React Router
    if (window.location.pathname !== location.pathname)
      navigate(window.location.pathname);
  }, [window.location.pathname, location.pathname]);

  useToolNameFromUrlListener();

  if (!authRequired) {
    return <AppRoutes user={null} />;
  } else if (!isAuthenticated) {
    return <LoginPage />;
  } else {
    return (
      <PTAUserContext.Provider value={{ user }}>
        <InactivityManager callback={logoutSession}>
          <AppRoutes user={user} />
        </InactivityManager>
      </PTAUserContext.Provider>
    );
  }
};
