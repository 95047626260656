import { isValid, parse } from "date-fns";

import { dateFormat } from "../../constants";
import { FormValues } from "../../types";

const dateRegExp = /^\d{2} \/ \d{2} \/ \d{4}$/;
const errorMessage = "invalid-date";

const validateDates = (formValues: FormValues, fields: string[]) => {
  return fields.reduce<Record<string, string>>((errors, field) => {
    const value = formValues[field];
    if (value && !dateRegExp.test(value)) {
      errors[field] = errorMessage;
    } else if (value) {
      const date = parse(value, dateFormat, new Date());
      if (!isValid(date)) {
        errors[field] = errorMessage;
      }
    }
    return errors;
  }, {});
};

export default validateDates;
