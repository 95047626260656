import React from "react";
import { Link } from "react-router-dom";

import "./NavBar.scss";
import UserNav from "./UserNav";

const NavBar = () => {
  return (
    <div className="nav-bar">
      <div className="title">
        <Link to="/tool-selection" aria-label="home" className="logo-link" />
        <span className="title-text">Property Tax-Aide</span>
      </div>

      <UserNav />
    </div>
  );
};

export default NavBar;
