import { useState, useEffect } from "react";

export default function useTimer(milliseconds: number) {
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFinished(true);
    }, milliseconds);

    return () => clearTimeout(timer);
  }, [milliseconds]);

  return isFinished;
}
