import api from "./api";
import { isAccessTokenCurrent } from "./isAccessTokenCurrent";
import memoize from "../util/memoize";

type ToolList = {
  tools: { toolName: string }[];
  translations: Record<string, Record<string, string>>;
};

type ToolListFunc = () => Promise<ToolList>;

const toolGetter = memoize(() => api.get("/tools"));

export const getTools: ToolListFunc = async () => {
  if (isAccessTokenCurrent()) {
    const resp = await toolGetter();

    return resp.data;
  } else {
    return Promise.reject(
      "getTools: access token has expired. Aborting request.",
    );
  }
};
