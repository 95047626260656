import React, { DetailedHTMLProps, FC } from "react";
import classNames from "classnames";
import "./Button.scss";

export type ButtonVariant = "primary" | "secondary" | "green" | "outline";

interface ButtonProps
  extends DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant: ButtonVariant;
}

const Button: FC<ButtonProps> = ({ variant, ...buttonProps }) => {
  return (
    <button
      {...buttonProps}
      className={classNames("Button", variant, buttonProps.className)}
    />
  );
};

export default Button;
