import classNames from "classnames";
import React from "react";

import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import { RoutesWrapper } from "./RoutesWrapper";
import EnvironmentAlert from "./components/EnvironmentAlert";
import env from "./env";
import "./fonts.scss";
import initI18Next from "./translations";

initI18Next();

export const App = () => {
  return (
    <div className="App">
      {env && env !== "prod" && <EnvironmentAlert env={env} />}

      <div
        className={classNames("app-content", {
          "allow-environment-alert": env !== "prod",
        })}
      >
        <BrowserRouter>
          <RoutesWrapper />
        </BrowserRouter>
      </div>
    </div>
  );
};
