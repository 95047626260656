import { getAddressFormElements } from "../../components/form/address_autocomplete/getAddressFormElements";
import isAccordion from "../../tools/utils/isAccordion";
import isWorksheet from "../../tools/utils/isWorksheet";
import {
  Accordion,
  AddressAutocompleteFieldNames,
  FormElement,
  FormRow,
  HybridFormElement,
  Page,
} from "../../types";

const COMPONENT_FIELDS = "componentFields";
const FORM_ELEMENTS = "formElements";
const INDENTED_ROWS = "indentedRows";

const extractFormElements = (page: Page) => {
  const formElements: HybridFormElement[] = [];
  if (page) {
    page.formRows.forEach((maybeRow) => {
      if ("tables" in maybeRow) {
        const worksheet = maybeRow;
        worksheet.tables.forEach((tableOrRow) => {
          if ("rows" in tableOrRow) {
            const table = tableOrRow;
            table.rows.forEach((row) => {
              row.cells.forEach(({ formElement }) =>
                formElements.push(formElement),
              );
            });
          } else {
            const row = tableOrRow;
            row.formElements.forEach((formElement) =>
              formElements.push(formElement),
            );
          }
        });
      } else if (isAccordion(maybeRow)) {
        const accordion: Accordion = maybeRow;
        for (let i = 1; i <= accordion.maxItems; i++) {
          accordion.formRows.forEach((row) => {
            row.formElements.forEach((formElement) => {
              if (isWorksheet(formElement)) {
                formElement.tables.forEach((table) => {
                  if ("rows" in table) {
                    table.rows.forEach((row) => {
                      row.cells.forEach((cell) => {
                        if (cell.type === "table-data") {
                          formElements.push({
                            type: `${cell.formElement.type}`,
                            name: `${cell.formElement.field.name}-${i}`,
                            field: {
                              ...cell.formElement.field,
                              name: `${cell.formElement.field.name}-${i}`,
                            },
                          });
                        }
                      });
                    });
                  } else {
                    const row = table;
                    row.formElements.forEach((formElement) =>
                      formElements.push(formElement),
                    );
                  }
                });
              } else if (formElement.field) {
                formElements.push({
                  ...formElement,
                  name: `${formElement.name}-${i}`,
                  field: {
                    ...formElement.field,
                    name: `${formElement.field.name}-${i}`,
                  },
                });
              } else {
                formElements.push({
                  ...formElement,
                  name: `${formElement.name}-${i}`,
                });
              }
            });
          });
        }
      } else if (INDENTED_ROWS in maybeRow) {
        const { indentedRows } = maybeRow;
        indentedRows.formRows.forEach((formRow) => {
          formRow.formElements.forEach((formElement) => {
            formElements.push(formElement);
          });
        });
      } else if (FORM_ELEMENTS in maybeRow) {
        const row = maybeRow;
        row.formElements.forEach((formElement) => {
          formElements.push(formElement);
          if (
            formElement.type === "address-autocomplete" &&
            formElement.field?.allowManualEntry &&
            formElement.field?.addressAutocompleteFieldNames
          ) {
            extractAddressFormElements(
              formElement.field.addressAutocompleteFieldNames,
              formElements,
              formElement.field.allowCanadianFields,
            );
          }
        });
      } else if (COMPONENT_FIELDS in maybeRow) {
        maybeRow.componentFields.forEach((componentField) => {
          formElements.push(componentField);
        });
      }
    });
  }
  return formElements;
};

const extractAddressFormElements = (
  addressFieldNames: AddressAutocompleteFieldNames,
  formElements: HybridFormElement[],
  allowCanadianFields?: boolean,
) => {
  const addressFormRows = getAddressFormElements(
    addressFieldNames,
    undefined,
    allowCanadianFields,
  );
  addressFormRows.forEach((row: FormRow) => {
    row.formElements.forEach((element: FormElement) => {
      formElements.push(element);
    });
  });
};

export default extractFormElements;
