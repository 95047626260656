export const dateFormat = "MM / dd / yyyy";

export enum PageType {
  SURVEY,
  ELIGIBILITY,
  APPLICATION,
  REVIEW,
}

export enum YesNo {
  YES = "Yes",
  NO = "No",
}
