import { getClientConsentAndAssignmentConfig } from "./getClientConsentAndAssignmentConfig";
import { getInstructionsConfig } from "./getInstructionsConfig";
import { getQuestionnaireConfig } from "./getQuestionnaireConfig";

export const getSurveyConfig = () => {
  return [
    getClientConsentAndAssignmentConfig(),
    getInstructionsConfig(),
    getQuestionnaireConfig(),
  ];
};
