import React, { FC, PropsWithChildren, useEffect, useState } from "react";

import LoadingSpinner from "./LoadingSpinner";

type Props<T> = {
  component: FC<T>;
  request: () => Promise<T>;
};

const Fetch = <T extends {}>({
  component: Component,
  request,
}: PropsWithChildren<Props<T>>) => {
  const [value, setValue] = useState<T>();
  const [, setError] = useState<any>();

  useEffect(() => {
    let canceled = false;
    request()
      .then((value) => {
        if (!canceled) setValue(value);
      })
      .catch((error) =>
        setError(() => {
          throw error;
        }),
      );

    return () => {
      canceled = true;
      setValue(undefined);
    };
  }, [request]);

  if (!value) return <LoadingSpinner />;

  return <Component {...value} />;
};

export default Fetch;
