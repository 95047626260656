import classNames from "classnames";
import React, { DetailedHTMLProps, FC } from "react";
import CloseIcon from "../icons/CloseIcon";

import "./CloseButton.scss";

interface CloseButtonProps
  extends DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

const CloseButton: FC<CloseButtonProps> = (props) => {
  return (
    <button
      {...props}
      className={classNames("CloseButton", props.className)}
      aria-label="close"
    >
      <CloseIcon />
    </button>
  );
};

export default CloseButton;
