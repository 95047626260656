import { Field, FormValues } from "../../types";

const validateMaxLength = (
  formValues: FormValues,
  fields: Field[],
): Record<string, string | [string, object]> => {
  return fields.reduce((errors, field) => {
    const value = formValues[field.name];
    if (
      field.maxLength &&
      typeof value === "string" &&
      value &&
      field.maxLength < value.length
    ) {
      errors[field.name] = ["too-long", { length: field.maxLength }];
    }
    return errors;
  }, {});
};

export default validateMaxLength;
