import isAccordion from "../../tools/utils/isAccordion";
import {
  Accordion,
  FormRow,
  FormValues,
  Page,
  UnionToolConfig,
  Visibility,
  Worksheet,
} from "../../types";
const getVisibilityForSingleAccordion = (
  accordion: Accordion,
  count: number,
) => {
  const visibility: Visibility = {};

  const fields: string[] = [];
  accordion.formRows.forEach((row) => {
    row.formElements.forEach((element) => {
      if (!element.field) return;
      fields.push(element.field.name);
    });
  });

  for (let i = 1; i <= accordion.maxItems; i++) {
    fields.forEach((field) => {
      visibility[`${field}-${i}`] = i <= count;
    });
  }

  return visibility;
};

const extractPagesFromToolConfig = (toolConfig: UnionToolConfig) => {
  const isLegacyTool = !!toolConfig.eligibility;

  return isLegacyTool
    ? [toolConfig.eligibility, ...toolConfig.application]
    : [...toolConfig.application];
};

export const calculateAccordionVisibility = (
  toolConfig: UnionToolConfig,
  formValues: FormValues,
) => {
  let visibility: Visibility = {};
  const isLegacyTool = !!toolConfig.eligibility;

  const pages: Page[] = extractPagesFromToolConfig(toolConfig);

  pages.forEach((page) => {
    page.formRows.forEach((row: Accordion | FormRow | Worksheet) => {
      if (isAccordion(row)) {
        const accordion: Accordion = row;

        const accordionCount = parseInt(formValues[accordion.field]) || 0;
        const newAccordionVisibility = getVisibilityForSingleAccordion(
          accordion,
          accordionCount,
        );

        visibility = { ...visibility, ...newAccordionVisibility };
      }
    });
  });

  return visibility;
};
