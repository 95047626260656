import { map, share, combineLatest, Observable, BehaviorSubject } from "rxjs";

import {
  FormValues,
  UnionToolConfig,
  ToolConfigFilterFunction,
} from "../types";

type BuildToolConfigFunction = (
  formValuesObs: Observable<FormValues>,
  toolConfigBehaviorSubject: BehaviorSubject<UnionToolConfig>,
  toolConfigFilter?: ToolConfigFilterFunction,
) => Observable<UnionToolConfig>;

const buildToolConfig: BuildToolConfigFunction = (
  formValuesObs,
  toolConfigBehaviorSubject,
  toolConfigFilter,
) => {
  return combineLatest([formValuesObs, toolConfigBehaviorSubject]).pipe(
    map(([formValues, toolConfig]) => {
      if (toolConfigFilter) {
        return toolConfigFilter(formValues, toolConfig);
      } else {
        return toolConfig;
      }
    }),
    share(),
  );
};

export default buildToolConfig;
