import { Field, FormElement, FormValues, Visibility } from "../../types";

const getVisibleFields = (
  formValues: FormValues,
  formElements: FormElement[],
  calculateVisibility: (formValues: FormValues) => Visibility,
) => {
  const visibleFields = new Array<Field>();
  const visibility = calculateVisibility(formValues);
  formElements.forEach((element) => {
    if (visibility[element.name] !== false) {
      if (element.field) visibleFields.push(element.field);
    }
  });
  return visibleFields;
};

export default getVisibleFields;
