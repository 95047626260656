import { FormApi } from "final-form";
import { Observable } from "rxjs";

import { FormValues } from "../types";

const buildCalculatedUpdatesDecorator =
  (calculatedValues: Observable<FormValues>) => (formApi: FormApi) => {
    const subscription = calculatedValues.subscribe((values) =>
      Object.entries(values).forEach(([name, value]) =>
        formApi.change(name, value),
      ),
    );
    return () => subscription.unsubscribe();
  };

export default buildCalculatedUpdatesDecorator;
