import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import currentToolName from "./currentToolName";

const useToolNameFromUrlListener = () => {
  const [previousToolName, setPreviousToolName] = useState("");

  const location = useLocation();

  const updateToolName = (pathname: string) => {
    const match = matchPath(
      {
        path: "/tools/:toolName/*",
      },
      pathname,
    );

    if (!match && previousToolName !== "") {
      setPreviousToolName("");
      currentToolName.next("");
    }

    if (
      match &&
      !!match.params.toolName &&
      match.params.toolName !== previousToolName
    ) {
      setPreviousToolName(match.params.toolName);
      currentToolName.next(match.params.toolName);
    }
  };

  useEffect(() => {
    updateToolName(location.pathname);
  }, [location]);
};

export default useToolNameFromUrlListener;
