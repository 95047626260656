import React from "react";

const CloseIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6773 9L17.7366 2.94085C18.087 2.59041 18.087 2.02173 17.7366 1.67074L16.3286 0.262824C15.9782 -0.087608 15.4095 -0.087608 15.0585 0.262824L8.99972 6.32254L2.94037 0.263387C2.58993 -0.0870454 2.02123 -0.0870454 1.67023 0.263387L0.262832 1.67074C-0.0876107 2.02117 -0.0876107 2.58985 0.262832 2.94085L6.32218 9L0.262832 15.0592C-0.0876107 15.4096 -0.0876107 15.9783 0.262832 16.3293L1.67079 17.7372C2.02123 18.0876 2.58993 18.0876 2.94094 17.7372L8.99972 11.6775L15.0591 17.7366C15.4095 18.087 15.9782 18.087 16.3292 17.7366L17.7372 16.3287C18.0876 15.9783 18.0876 15.4096 17.7372 15.0586L11.6773 9Z"
      fill="#441D32"
    />
  </svg>
);

export default CloseIcon;
