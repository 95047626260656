import { formatCurrencyWithoutDecimalsIfDefined } from "../utils/pdfHelpers";
import { INCOME_THRESHOLDS } from "./constants";

export default {
  "form-elements": {
    "survey-instructions-header": {
      title: "Instructions",
    },
    "survey-instructions-opening": {
      title:
        "AARP Foundation Property Tax-Aide does not collect or share your personal information with other individuals or organizations without your consent, except when required by applicable law.",
    },
    "survey-instructions-quote": {
      title:
        "You are being asked to volunteer to complete a 5-minute survey about property tax relief program services and benefits. AARP Foundation is asking for this information to help guide its discussions about maintaining and improving the AARP Foundation Property Tax-Aide program.\n\nIf you decide not to participate in the survey or answer only some of the survey questions, you will not have a loss of benefits to which you might otherwise be entitled. Please be assured that your answers to the survey questions are anonymous and confidential. Your responses will be analyzed with responses from other AARP Foundation Property Tax-Aide program participants.",
    },
    "survey-client-consent-header": {
      title: "Client Consent Form",
    },
    "survey-client-consent-text-1": {
      title: "I, ",
    },
    "survey-client-consent-text-2": {
      title: ",",
    },
    "survey-client-consent-text-3": {
      title: "provide my consent to the following:",
    },
    "survey-client-consent-text-4": {
      title:
        "AARP Foundation has several valuable free programs and services dealing with income, housing, hunger, volunteering, employment, and more that may be of interest to you.",
    },
    "survey-questionnaire-header": {
      title: "Questionnaire",
    },
    "survey-questionnaire-almost-done": {
      title:
        "<strong>We are almost done!</strong> The following questions are about you. Answering these questions is optional. AARP Foundation is asking for this information to help enhance its outreach approaches so more people can benefit from the program. <br/> <br/> Remember that your responses are anonymous and confidential and will be analyzed with responses from other AARP Foundation Property Tax-Aide program participants.",
    },
    "survey-questionnaire-thank-you": {
      title:
        "<strong>Thank you for helping improve AARP Foundation's property tax relief services.</strong>",
    },
    "survey-applicant-email-description": {
      title:
        "Please provide your email address to receive communications about AARP Foundation Programs.",
    },
  },
  fields: {
    "survey-1-client-name": {
      placeholder: "Client Name",
    },
    "survey-2-agree-to-share": {
      label:
        "I agree to share personal information with AARP Foundation for the following purposes: (1) to determine whether I may be eligible for a property tax credit or refund; and (2) to help complete my application for a property tax credit or refund if I am eligible.",
    },
    "survey-3-allow-to-send-info": {
      label:
        "Please check this box if you want to allow AARP Foundation—the charitable affiliate of AARP—to send you information about free programs and services. Your data will not be shared with AARP or AARP’s licensed service providers for the purposes of membership marketing or paid offers.",
    },
    "survey-4-applicant-email": {
      label: "Applicant Email Address",
    },
    "survey-5-co-applicant-email": {
      label: "Co-Applicant Email Address",
    },
    "survey-6-client-consent-date": {
      label: "Date",
    },
    "survey-7-user-assignment-select": {
      label: "Volunteer Assignment",
    },
    "survey-over-fifty": {
      label:
        "Are any members of your household 50 years or older as of the time of the application?",
    },
    "survey-questionnaire-question-3-birth-year": {
      label: "In what year were you born? (Enter a four-digit number)",
      placeholder: "Birth Year",
    },
    "survey-questionnaire-question-4": {
      label: "What is your current gender identity? (select all that apply)",
      options: {
        "survey-gender-male": "Male",
        "survey-gender-female": "Female",
        "survey-gender-non-binary": "Non-Binary",
        "survey-gender-show-self-describe": "Prefer to self-describe",
        "survey-gender-prefer-not-to-answer": "Prefer not to answer",
      },
    },
    "survey-gender-self-describe": {
      label: "What is your gender?",
    },
    "survey-questionnaire-question-5": {
      label:
        "Which of the following best describes your race? (select all that apply)",
      options: {
        "survey-race-american-indian-alaska-native":
          "American Indian or Alaska Native",
        "survey-race-asian": "Asian",
        "survey-race-black-afican-american": "Black or African American",
        "survey-race-native-hawaiian-or-pacific-islander":
          "Native Hawaiian or Other Pacific Islander",
        "survey-race-white": "White or Caucasian",
        "survey-race-multi-racial": "Multi-Racial",
        "survey-race-prefer-to-self-describe": "Prefer to self-describe",
        "survey-race-prefer-not-to-answer": "Prefer not to answer",
      },
    },
    "survey-race-self-description": {
      label: "Race: Self-Description",
    },
    "survey-questionnaire-question-6": {
      label: "Are you of Hispanic, Latino, or Spanish origin or descent?",
      options: {
        "survey-hispanic-descent-yes": "Yes",
        "survey-hispanic-descent-no": "No",
        "survey-hispanic-descent-prefer-not-to-answer": "Prefer not to answer",
      },
    },
    "survey-questionnaire-question-7": {
      label:
        "How many people, including you, are part of your household? (select one)",
      options: {
        "survey-household-size-1": "1 (yourself)",
        "survey-household-size-2": "2",
        "survey-household-size-3": "3",
        "survey-household-size-4-plus": "4 or more",
      },
    },
    "survey-questionnaire-question-household-1": {
      label: `Was your annual household income more than $${formatCurrencyWithoutDecimalsIfDefined(
        INCOME_THRESHOLDS.HOUSEHOLD_SIZE_1,
      )} last year?`,
      options: {
        "survey-household-1-yes": "Yes",
        "survey-household-1-no": "No",
        "survey-household-1-prefer-not-to-answer": "Prefer not to answer",
      },
    },
    "survey-questionnaire-question-household-2": {
      label: `Was your annual household income more than $${formatCurrencyWithoutDecimalsIfDefined(
        INCOME_THRESHOLDS.HOUSEHOLD_SIZE_2,
      )} last year?`,
      options: {
        "survey-household-2-yes": "Yes",
        "survey-household-2-no": "No",
        "survey-household-2-prefer-not-to-answer": "Prefer not to answer",
      },
    },
    "survey-questionnaire-question-household-3": {
      label: `Was your annual household income more than $${formatCurrencyWithoutDecimalsIfDefined(
        INCOME_THRESHOLDS.HOUSEHOLD_SIZE_3,
      )} last year?`,
      options: {
        "survey-household-3-yes": "Yes",
        "survey-household-3-no": "No",
        "survey-household-3-prefer-not-to-answer": "Prefer not to answer",
      },
    },
    "survey-questionnaire-question-household-4-or-more": {
      label: `Was your annual household income more than $${formatCurrencyWithoutDecimalsIfDefined(
        INCOME_THRESHOLDS.HOUSEHOLD_SIZE_4,
      )} last year?`,
      options: {
        "survey-household-4-or-more-yes": "Yes",
        "survey-household-4-or-more-no": "No",
        "survey-household-4-or-more-prefer-not-to-answer":
          "Prefer not to answer",
      },
    },
    "survey-questionnaire-question-lgbtq": {
      label:
        "Do you identify as LGBTQ? (Lesbian, Gay, Bisexual, Transgender, Queer/Questioning)",
      options: {
        "survey-lgbtq-yes": "Yes",
        "survey-lgbtq-no": "No",
        "survey-lgbtq-prefer-not-to-answer": "Prefer not to answer",
      },
    },
    "survey-questionnaire-disability": {
      label:
        "Do you have a permanent disability or chronic condition that hinders or limits the amount or kind of activities you can do?",
      options: {
        "survey-disability-yes": "Yes",
        "survey-disability-no": "No",
        "survey-disability-prefer-not-to-answer": "Prefer not to answer",
      },
    },
  },
};
