import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "./en.json";

const initI18Next: () => void = () => {
  i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    debug: false,
    react: {
      transKeepBasicHtmlNodesFor: [
        "br",
        "h2",
        "i",
        "li",
        "ol",
        "p",
        "strong",
        "ul",
      ],
    },
    resources: {
      en: {
        translation,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });
};

export default initI18Next;
