import React from "react";
import "./NavMenu.scss";
import NavLink from "./NavLink";
import useLogout from "../../models/auth/useLogout";

interface Props {
  close: () => void;
  isAdmin: boolean;
}

const NavMenu: React.FC<Props> = ({ close, isAdmin }) => {
  const onLogout = useLogout();

  return (
    <div className="NavMenu" onClick={close}>
      <NavLink linkText="Home" to="/tool-selection" />
      {isAdmin && <NavLink linkText="Admin Page" to="/admin" />}
      {isAdmin && <NavLink linkText="User Accounts" to="/users" />}
      <NavLink
        linkText="About PTA"
        href="https://ptaconsumers.aarpfoundation.org/about-property-tax-aide/"
      />
      <NavLink linkText="Log Out" callback={onLogout} />
    </div>
  );
};

export default NavMenu;
