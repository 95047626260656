import { Field, FormValues } from "../../types";

const validateMatchingFields = (
  formValues: FormValues,
  visibleFields: Field[],
) => {
  return visibleFields.reduce((errors, field) => {
    if (field.matchField) {
      if (formValues[field.name] !== formValues[field.matchField]) {
        errors[field.name] = "does-not-match";
      }
    }

    return errors;
  }, {});
};

export default validateMatchingFields;
