import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import ReactSelect, { DropdownIndicatorProps, components } from "react-select";

import "./Dropdown.scss";
import CaretDown from "./icons/CaretDown";

type Option = { label: string; value: string };

type Props = {
  options: { label: string; url: string }[];
};

const DropdownIndicator = (props: DropdownIndicatorProps<Option, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown />
    </components.DropdownIndicator>
  );
};
const IndicatorSeparator = () => null;

const Dropdown: FC<Props> = ({ children, options }) => {
  const navigate = useNavigate();

  return (
    <ReactSelect<Option>
      className="Dropdown"
      classNamePrefix="dropdown-select"
      components={{ DropdownIndicator, IndicatorSeparator }}
      onChange={(option) => {
        if (option !== null) {
          navigate(option.value);
        }
      }}
      options={options.map(({ url, ...rest }) => ({ ...rest, value: url }))}
      placeholder={children}
    />
  );
};

export default Dropdown;
