export const getQuestionnaireConfig = () => {
  return {
    pageNumber: 3,
    title: "Survey",
    pageName: "Questionnaire",
    formRows: [
      {
        formElements: [
          {
            name: "survey-questionnaire-header",
            type: "section-header",
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-question-3-birth-year",
            type: "survey-text",
            field: {
              name: "survey-questionnaire-question-3-birth-year",
              size: "survey-small",
              validateBirthYear: true,
              maxLength: 4,
            },
          },
        ],
        compactRow: false,
      },
      {
        formElements: [
          {
            name: "survey-over-fifty",
            type: "yes-no-radio",
            field: {
              name: "survey-over-fifty",
              type: "static-text",
            },
          },
        ],
        compactRow: false,
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-almost-done",
            type: "static-text",
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-question-4",
            type: "survey-multi-select",
            field: {
              name: "survey-questionnaire-question-4",
              options: [
                {
                  value: "survey-gender-male",
                },
                {
                  value: "survey-gender-female",
                },
                {
                  value: "survey-gender-non-binary",
                },
                {
                  value: "survey-gender-show-self-describe",
                },
                {
                  value: "survey-gender-prefer-not-to-answer",
                },
              ],
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-gender-self-describe",
            type: "survey-text",
            field: {
              name: "survey-gender-self-describe",
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-question-6",
            type: "survey-multi-select",
            exclusive: true,
            field: {
              name: "survey-questionnaire-question-6",
              options: [
                {
                  value: "survey-hispanic-descent-yes",
                },
                {
                  value: "survey-hispanic-descent-no",
                },
                {
                  value: "survey-hispanic-descent-prefer-not-to-answer",
                },
              ],
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-question-5",
            type: "survey-multi-select",
            field: {
              name: "survey-questionnaire-question-5",
              options: [
                {
                  value: "survey-race-american-indian-alaska-native",
                },
                {
                  value: "survey-race-asian",
                },
                {
                  value: "survey-race-black-afican-american",
                },
                {
                  value: "survey-race-native-hawaiian-or-pacific-islander",
                },
                {
                  value: "survey-race-white",
                },
                {
                  value: "survey-race-multi-racial",
                },
                {
                  value: "survey-race-prefer-to-self-describe",
                },
                {
                  value: "survey-race-prefer-not-to-answer",
                },
              ],
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-race-self-description",
            type: "survey-text",
            field: {
              name: "survey-race-self-description",
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-question-7",
            exclusive: true,
            type: "survey-multi-select",
            field: {
              name: "survey-questionnaire-question-7",
              options: [
                {
                  value: "survey-household-size-1",
                },
                {
                  value: "survey-household-size-2",
                },
                {
                  value: "survey-household-size-3",
                },
                {
                  value: "survey-household-size-4-plus",
                },
              ],
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-question-household-1",
            type: "survey-multi-select",
            exclusive: true,
            field: {
              name: "survey-questionnaire-question-household-1",
              options: [
                {
                  value: "survey-household-1-yes",
                },
                {
                  value: "survey-household-1-no",
                },
                {
                  value: "survey-household-1-prefer-not-to-answer",
                },
              ],
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-question-household-2",
            type: "survey-multi-select",
            exclusive: true,
            field: {
              name: "survey-questionnaire-question-household-2",
              options: [
                {
                  value: "survey-household-2-yes",
                },
                {
                  value: "survey-household-2-no",
                },
                {
                  value: "survey-household-2-prefer-not-to-answer",
                },
              ],
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-question-household-3",
            type: "survey-multi-select",
            exclusive: true,
            field: {
              name: "survey-questionnaire-question-household-3",
              options: [
                {
                  value: "survey-household-3-yes",
                },
                {
                  value: "survey-household-3-no",
                },
                {
                  value: "survey-household-3-prefer-not-to-answer",
                },
              ],
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-question-household-4-or-more",
            type: "survey-multi-select",
            exclusive: true,
            field: {
              name: "survey-questionnaire-question-household-4-or-more",
              options: [
                {
                  value: "survey-household-4-or-more-yes",
                },
                {
                  value: "survey-household-4-or-more-no",
                },
                {
                  value: "survey-household-4-or-more-prefer-not-to-answer",
                },
              ],
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-disability",
            type: "survey-multi-select",
            exclusive: true,
            field: {
              name: "survey-questionnaire-disability",
              options: [
                {
                  value: "survey-disability-yes",
                },
                {
                  value: "survey-disability-no",
                },
                {
                  value: "survey-disability-prefer-not-to-answer",
                },
              ],
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-question-lgbtq",
            type: "survey-multi-select",
            exclusive: true,
            field: {
              name: "survey-questionnaire-question-lgbtq",
              options: [
                { value: "survey-lgbtq-yes" },
                { value: "survey-lgbtq-no" },
                { value: "survey-lgbtq-prefer-not-to-answer" },
              ],
            },
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-questionnaire-thank-you",
            type: "static-text",
          },
        ],
      },
    ],
  };
};
