import { Observable } from "rxjs";
import {
  map,
  pairwise,
  share,
  startWith,
  withLatestFrom,
} from "rxjs/operators";
import { FormValues, Visibility } from "../types";
import { shouldLogCalculatedFields } from "./conditionalLogging";

const buildCalculatedValues = (
  resolveCalculatedValues: (values: FormValues) => FormValues,
  resolveConditionalVisibility: (values: FormValues) => Visibility,
  userUpdatesObs: Observable<FormValues>,
) => {
  const clearedValuesObs = userUpdatesObs.pipe(
    map(resolveConditionalVisibility),
    startWith({} as Visibility),
    pairwise(),
    map(([prevVisibility, currVisibility]) => {
      return Object.entries(currVisibility)
        .filter(
          ([field, isVisible]) =>
            isVisible === false && prevVisibility[field] !== false,
        )
        .map<string>(([field]) => field);
    }),
    share(),
  );

  return userUpdatesObs.pipe(
    withLatestFrom(clearedValuesObs),
    map(clearedValues),
    map((userUpdates) => {
      const calculatedValues = !!userUpdates
        ? {
            ...resolveCalculatedValues(userUpdates),
          }
        : {};

      if (shouldLogCalculatedFields) {
        console.info("Calculated Fields:", calculatedValues);
      }

      return calculatedValues;
    }),
    share(),
  );
};

export default buildCalculatedValues;

export const clearedValues = ([values, clearedValues]) => {
  if (clearedValues.length) {
    clearedValues.forEach((key) => delete values[key]);
  }
  return values;
};
