const routes = {
  admin: "/admin",
  application: "/tools/:toolName/application/:pageNumber",
  applicationReview: "/tools/:toolName/application/:pageNumber/review",
  eligibility: "/tools/:toolName/eligibility",
  nps: "/tools/:toolName/nps",
  survey: "/tools/:toolName/survey/:pageNumber",
  taxProgramsByState: (usState: string) => `/states/${usState}/tax_programs`,
  thankYou: "/tools/:toolName/thank-you",
  toolSelection: "/tool-selection",
  users: "/users",
};

export default routes;
