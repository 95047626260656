import jwtDecode, { JwtPayload } from "jwt-decode";
import { appConfig } from "../appConfig";

export const isAccessTokenCurrent = (storage = sessionStorage) => {
  const token = storage.getItem("auth-token");
  const { authRequired } = appConfig;

  if (!authRequired) return true;
  if (!token) return false;

  const decoded = jwtDecode<JwtPayload>(token);
  const { exp } = decoded;

  if (!exp) return false;

  const thirtySecondsFromExpiration = exp * 1000 + 30000;

  if (Date.now() < thirtySecondsFromExpiration) {
    return true;
  } else {
    return false;
  }
};
