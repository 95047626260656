import { Field, FormValues } from "../../types";

const validateRequired = (formValues: FormValues, visibleFields: Field[]) => {
  return visibleFields.reduce((errors, field) => {
    const isRequired = !field.optional && !field.readOnly;

    const value = formValues[field.name];
    const isBlank =
      value === false ||
      value == null ||
      value === "" ||
      (Array.isArray(value) && value.length === 0);

    if (isRequired && isBlank) {
      errors[field.name] = "required";
    }
    return errors;
  }, {});
};

export default validateRequired;
