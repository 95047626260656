import React, { useContext, createContext } from "react";
import currentTool, { toolDescriptionHydrator } from "../models/currentTool";
import useObservable from "../models/utils/useObservable";

type CurrentToolProviderProps = {
  children: React.ReactNode;
};
const CurrentToolContext = createContext<
  ReturnType<typeof toolDescriptionHydrator>
>({
  toolName: "",
  pending: true,
});

export const useCurrentTool = () => useContext(CurrentToolContext);

export default function CurrentToolProvider({
  children,
}: CurrentToolProviderProps) {
  const currentToolValue = useObservable(currentTool);

  return (
    <CurrentToolContext.Provider value={currentToolValue}>
      {children}
    </CurrentToolContext.Provider>
  );
}
