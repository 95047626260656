import { BehaviorSubject } from "rxjs";
import { PageRoute, PageType } from "../types";
import synchronousAccessToLastObservableValue from "./utils/synchronousAccessToLastObservableValue";

const buildPageCompletion = (
  nextPageToComplete: BehaviorSubject<PageRoute>,
) => {
  const getNextPageToComplete =
    synchronousAccessToLastObservableValue(nextPageToComplete);

  const setNextPageToComplete = (pageType: PageType, pageNumber: number) => {
    const oldNextPage = getNextPageToComplete();
    if (
      pageType > oldNextPage.pageType ||
      (pageType === oldNextPage.pageType && pageNumber > oldNextPage.pageNumber)
    ) {
      nextPageToComplete.next({ pageType: pageType, pageNumber: pageNumber });
    }
  };
  return {
    getNextPageToComplete,
    setNextPageToComplete,
  };
};

export default buildPageCompletion;
