import classNames from "classnames";
import React, { FC, ReactNode, useEffect, useRef } from "react";
import Button from "../buttons/Button";
import CloseButton from "../buttons/CloseButton";
import "./Popup.scss";

const ESC_KEY = "Escape";
interface PopupProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  customButtons?: ReactNode;
  image?: "happy" | "unhappy" | undefined;
}

const Popup: FC<PopupProps> = ({
  open,
  onClose,
  children,
  image,
  customButtons,
}) => {
  const focusedElementRef = useRef(document.activeElement);
  const popupRef = useRef(null);

  const resetFocusOnClose = () => {
    (focusedElementRef?.current as HTMLElement)?.focus();
  };

  useEffect(() => {
    if (open) {
      focusedElementRef.current = document.activeElement;

      if (popupRef?.current) {
        (popupRef.current as HTMLElement).focus();
      }
    } else {
      resetFocusOnClose();
    }
  }, [open]);

  const closePopupOnEscKey = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === ESC_KEY) {
      onClose();
    }
  };

  return (
    <div
      role="dialog"
      className={classNames("Popup", { open: open, closed: !open })}
      onKeyDown={closePopupOnEscKey}
      ref={popupRef}
      tabIndex={-1}
    >
      {open ? (
        <div className="popup-modal">
          {image ? (
            <div
              className={classNames("image-container", image)}
              data-testid="image-container"
            />
          ) : null}

          <div className="popup-content">
            <div className="close-button-container">
              <CloseButton onClick={onClose} className="close-button" />
            </div>
            <div className="popup-text">{children}</div>

            <div className="popup-buttons">
              {customButtons ?? (
                <Button
                  variant="primary"
                  onClick={onClose}
                  aria-label="dismiss"
                >
                  Dismiss
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Popup;
