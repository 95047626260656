import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { FormValues, Visibility } from "../../types";

const buildGetElementVisibilityObservable = (
  calculateVisibility: (values: FormValues) => Visibility,
  formValues: Observable<FormValues>,
) => {
  const visibilityObservables = formValues.pipe(
    map(calculateVisibility),
    shareReplay(1),
  );

  const getElementVisibiltyObservable = (element: string) =>
    visibilityObservables.pipe(
      map((visibility) => visibility[element] ?? true),
    );

  return getElementVisibiltyObservable;
};

export default buildGetElementVisibilityObservable;
