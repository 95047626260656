import calculateSurveyVisibility from "../../tools/survey/calculateSurveyVisibility";
import { FormValues, UnionToolConfig, Visibility } from "../../types";
import { calculateAccordionVisibility } from "./calculateAccordionVisibility";
import { calculateAutocompleteVisibility } from "./calculateAutocompleteVisibility";

const generateVisibilityFunction = (
  calculateVisibility: (values: FormValues) => Visibility,
  toolConfig: UnionToolConfig,
) => {
  const getVisibileFields = (formValues: FormValues): Visibility => {
    if (!formValues) {
      return {};
    } else {
      const visibility = {
        ...calculateSurveyVisibility(formValues),
        ...calculateAccordionVisibility(toolConfig, formValues),
        ...calculateVisibility(formValues),
      };

      return calculateAutocompleteVisibility(
        toolConfig,
        formValues,
        visibility,
      );
    }
  };

  return getVisibileFields;
};

export default generateVisibilityFunction;
