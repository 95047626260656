export const getInstructionsConfig = () => {
  return {
    pageNumber: 2,
    title: "Survey",
    pageName: "Instructions",
    formRows: [
      {
        dense: true,
        formElements: [
          {
            name: "survey-instructions-header",
            type: "section-header",
          },
        ],
      },
      {
        dense: true,
        formElements: [
          {
            name: "survey-instructions-opening",
            type: "static-text",
          },
        ],
      },
      {
        formElements: [
          {
            name: "survey-instructions-quote",
            type: "block-quote",
          },
        ],
      },
    ],
  };
};
