import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

import routes from "../routeDefinitions";

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN || ""}
      clientId={process.env.AUTH0_SPA_CLIENT_ID || ""}
      cacheLocation={"localstorage"}
      authorizationParams={{
        audience: process.env.AUTH0_API_IDENTIFIER || "",
        redirect_uri: `${window.location.origin}${routes.toolSelection}`,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
