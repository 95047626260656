import parseCurrency from "./parseCurrency";
import { FullAddressInfo } from "../../types";
import { format } from "date-fns";
import { formatCurrencyRoundToNearestDollar } from "./formatCurrencyRoundToNearestDollar";

// String stripping
export const stripNonNumbers = (str: string = "") => str.replace(/\D/g, "");

export const stripCommas = (str?: string) => (str ? str.replace(",", "") : str);

export const stripParens = (str: string) => {
  return str.replace("(", "").replace(")", " ");
};

export const stripWhitespace = (str: string) =>
  str ? str.replace(/\s/g, "") : str;

export const stripNotLetters = (str: string) =>
  str ? str.replace(/[^a-zA-Z]/g, "") : "";

// String modification
export const truncate = (str: string, length: number) => {
  return str ? str.substring(0, length) : "";
};

export const capitalize = (str?: string) =>
  str ? str[0].toUpperCase() + str.slice(1) : str;

// Currency & rounding
export const round2 = (str?: string) => {
  if (!str) return str;
  const num = parseFloat(str);
  return (Math.round(num * 100) / 100).toString();
};

export const padCurrency = (str?: string): string => {
  const roundedStr = round2(str);
  if (!roundedStr) return "0.00";
  const [integers, decimals = "00"] = roundedStr.split(".");
  return `${integers}.${decimals.padEnd(2, "0")}`;
};

export const removeDecimal = (value: string | number | undefined) => {
  if (value) {
    return value.toString().replace(".", " ");
  }
  return "";
};

export const roundNumberToNearestDollar = (value?: string): string => {
  if (!value) return "";

  const isFloat = !!parseFloat(value);
  const hasDecimal = isFloat ? value.match(/\./) : false;
  const isNegative = isFloat ? parseFloat(value) < 0 : false;
  const isZero = parseFloat(value) === 0;

  if (isZero) {
    return "";
  } else if (hasDecimal) {
    const absVal = Math.round(Math.abs(parseCurrency(value)));

    return isNegative ? `-${absVal}` : `${absVal}`;
  } else {
    return value;
  }
};

export const roundNumbers = (fields: Record<string, string>) => {
  const fieldNames = Object.keys(fields);

  fieldNames.forEach((fieldName) => {
    fields[fieldName] = roundNumberToNearestDollar(fields[fieldName]);
  });
};

export const currencyFormatWithoutRounding = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const currencyFormatWithoutRoundingWithoutDecimals = new Intl.NumberFormat(
  "en-US",
  {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
);

export const formatCurrencyWithoutRounding = (str: string) => {
  return str ? currencyFormatWithoutRounding.format(parseCurrency(str)) : "";
};

export const roundToIntegerIfDefined = (
  value: number | undefined,
): string | undefined => {
  return value !== undefined ? Math.round(value).toString() : undefined;
};

export const roundTo2DecimalsIfDefined = (
  value: number | undefined,
): string | undefined => {
  return value !== undefined ? round2(value.toString()) : undefined;
};

export const toStringWithoutRoundingIfDefined = (
  value: number | undefined,
): string | undefined => {
  return value !== undefined ? value.toString() : undefined;
};

export const formatCurrencyWithoutDecimalsIfDefined = (
  value: number | undefined,
): string | undefined => {
  return !value
    ? undefined
    : currencyFormatWithoutRoundingWithoutDecimals.format(value);
};

export const roundAndRemoveCommasAndDecimals = (
  value: string | undefined,
): string | undefined => {
  return value
    ? formatCurrencyRoundToNearestDollar(value).replace(/,/g, "")
    : undefined;
};

export const roundAndEraseDecimal = (value: string | number | undefined) => {
  if (value) {
    const isNegative = parseFloat(String(value)) < 0;
    const absAmt = `${Math.round(Math.abs(parseCurrency(value))).toString()} 00`;

    return isNegative ? `-${absAmt}` : absAmt;
  } else {
    return "";
  }
};

// Percentages
export const percentAsDecimalNoLeadingZero = (str?: string) =>
  str
    ? (parseInt(str, 10) / 100).toFixed(2).toString().replace(/^0+/, "")
    : str;

export const decimalToPercent = (decimal: number): string | undefined => {
  return round2((decimal * 100).toString());
};

// Boolean / yes / no conversions
export const booleanAsYesNo = (value?: boolean) => {
  return value ? "Yes" : "No";
};

export const yesNoToYesOff = (yesNo: string | undefined) => {
  if (yesNo === "yes" || yesNo === "Yes") {
    return "Yes";
  } else if (yesNo === "no" || yesNo === "No") {
    return "Off";
  } else {
    return "Off";
  }
};

export const arrayContainsToYesOff =
  (option: string) => (value: string[] | undefined) => {
    if (!value) {
      return "Off";
    }
    return value.includes(option) ? "Yes" : "Off";
  };

export const booleanAsOnOff = (value: boolean) => {
  return value ? "On" : "Off";
};

export const removeSpaces = (str: string) => {
  return str.replace(/\s/g, "");
};

// Dates
export const formatDate = (str: string, dateFormat: string) => {
  if (!str) return "";

  // On Safari, Date cannot handle spaces when parsing date string
  str = removeSpaces(str);

  const date = new Date(str);
  return format(date, dateFormat);
};

export const formatDateWithNoSpaces = (dateString: string) => {
  if (!dateString) return "";

  return formatDate(dateString, "MM/dd/yyyy");
};

export const formatDateToMMDDYY = (date?: string): string => {
  if (!date) return "";

  const month = date.split(" / ")[0];
  const day = date.split(" / ")[1];
  const yr = date.split(" / ")[2].slice(-2);

  return `${month} - ${day} - ${yr}`;
};

// Addresses
export const formatAddressLine1 = (streetLine?: string, secondary?: string) => {
  if (streetLine === undefined) return undefined;
  return secondary ? `${streetLine}, ${secondary}` : streetLine;
};

export const formatFullAddress = (
  fullAddressInfo: FullAddressInfo,
  secondaryFromManualEntry?: string,
) => {
  const { street_line, secondary, city, state, zipcode } = fullAddressInfo;

  const secondaryAddressLine = secondaryFromManualEntry || secondary;

  const line1 = formatAddressLine1(street_line, secondaryAddressLine);
  const line2 = `${city}, ${state} ${zipcode}`;

  return `${line1}\n${line2}`;
};

// State-specific formatters
export const formatMIStreetAddress = (street1?: string, street2?: string) => {
  if (!street1) return "";

  const streetAddress = street1.split(" ");
  const buildingNo = streetAddress.shift();
  const mailingStreet1 = streetAddress.join(" ");

  return street2
    ? `${buildingNo} ${mailingStreet1}, ${street2}`
    : `${buildingNo} ${mailingStreet1}`;
};

export const formatMIFullAddress = (
  street?: string,
  city?: string,
  state?: string,
  zip?: string,
) => {
  if (!street || !city || !state || !zip) return "";

  return `${street}, ${city}, ${state}, ${zip}`;
};

export const formatMDName = (
  firstName?: string,
  lastName?: string,
  mi?: string,
): string => {
  if (!firstName && !lastName) return "";

  return mi ? `${lastName}, ${firstName}, ${mi}` : `${lastName}, ${firstName}`;
};
