import { FormValues } from "../../types";

const buildRegexpValidation =
  (regexp: RegExp, errorMessage: string) =>
  (formValues: FormValues, fields: string[]) => {
    return fields.reduce<Record<string, string>>((errors, field) => {
      if (formValues[field] && !regexp.test(formValues[field])) {
        errors[field] = errorMessage;
      }
      return errors;
    }, {});
  };

export default buildRegexpValidation;
