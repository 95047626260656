import React, {
  ComponentType,
  LazyExoticComponent,
  Suspense,
  lazy,
} from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CurrentToolProvider from "./components/CurrentTool";
import LoadingSpinner from "./components/LoadingSpinner";
import NavBar from "./components/navbar/NavBar";
import ToolSelectionPage from "./components/pages/ToolSelectionPage";
import memoize from "./util/memoize";

// A utility type for extracting and inferring the prop types of a component.
type ComponentProps<T> = T extends ComponentType<infer P> ? P : never;

function lazyComponent<T extends ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>,
) {
  const LazyComponent: LazyExoticComponent<T> = lazy(importFunc);

  return (props: ComponentProps<T>) => (
    <Suspense fallback={<LoadingSpinner />}>
      <LazyComponent {...props} />
    </Suspense>
  );
}

const AdminPage = lazyComponent(
  memoize(() => import("./components/pages/AdminPage")),
);
const ApplicationContainer = lazyComponent(
  memoize(() => import("./components/pages/ApplicationContainer")),
);
const Eligibility = lazyComponent(
  memoize(() => import("./components/pages/Eligibility")),
);
const MainContentPane = lazyComponent(
  memoize(() => import("./components/MainContentPane")),
);
const NpsPage = lazyComponent(
  memoize(() => import("./components/nps/NpsPage")),
);
const Review = lazyComponent(memoize(() => import("./components/pages/Review")));
const Survey = lazyComponent(memoize(() => import("./components/pages/Survey")));
const ThankYouPage = lazyComponent(
  memoize(() => import("./components/pages/ThankYouPage")),
);
const UsersPage = lazyComponent(
  memoize(() => import("./components/pages/UsersPage")),
);

import { PageType } from "./constants";
import routes from "./routeDefinitions";
import { PtaUser } from "./types";

type Props = {
  user?: PtaUser | null;
};

export const AppRoutes = (props: Props) => {
  const currentUser = props.user || null;

  return (
    <CurrentToolProvider>
      <NavBar />

      <Routes>
        <Route path={routes.toolSelection} element={<ToolSelectionPage />} />

        <Route
          path={routes.survey}
          element={
            <MainContentPane currentStage={PageType.SURVEY}>
              <Survey />
            </MainContentPane>
          }
        />

        <Route
          path={routes.eligibility}
          element={
            <MainContentPane currentStage={PageType.ELIGIBILITY}>
              <Eligibility />
            </MainContentPane>
          }
        />

        <Route
          path={routes.application}
          element={
            <MainContentPane currentStage={PageType.APPLICATION}>
              <ApplicationContainer />
            </MainContentPane>
          }
        />

        <Route
          path={routes.applicationReview}
          element={
            <MainContentPane currentStage={PageType.REVIEW}>
              <Review />
            </MainContentPane>
          }
        />

        <Route path={routes.nps} element={<NpsPage />} />

        <Route path={routes.thankYou} element={<ThankYouPage />} />

        <Route
          path={routes.admin}
          element={<AdminPage currentUser={currentUser} />}
        />

        <Route
          path={routes.users}
          element={<UsersPage currentUser={currentUser} />}
        />

        <Route path="*" element={<Navigate to={routes.toolSelection} replace/>} />
      </Routes>
    </CurrentToolProvider>
  );
};
