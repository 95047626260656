import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";

export const Auth0LoginButton = () => {
  const { t } = useTranslation();
  const { loginWithRedirect, isLoading } = useAuth0();

  return (
    <Button
      onClick={() => loginWithRedirect({appState: {returnTo: window.location.pathname}})}
      disabled={isLoading}
      variant="primary"
      data-testid="auth0-login"
    >
      {t("login.auth0-button")}
    </Button>
  );
};
