import React, { FC, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generatePath } from "react-router";
import routes from "../routeDefinitions";

import BasePage from "./pages/BasePage";
import Dropdown from "./Dropdown";
import Popup from "./modals/Popup";
import "./ToolSelection.scss";

type Props = {
  options: { toolName: string }[];
};

const ToolSelection: FC<Props> = ({ options }) => {
  const [open, setOpen] = useState(false);
  const { t: toolsTranslate } = useTranslation("tools");
  const { t } = useTranslation();

  const optionsWithLabel = useMemo(() => {
    return options.map((tool) => {
      const { toolName } = tool;
      return {
        label: toolsTranslate(`${toolName}.label`),
        url: generatePath(routes.survey, { toolName, pageNumber: 1 }),
      };
    });
  }, [options, toolsTranslate]);

  return (
    <BasePage>
      <main className="ToolSelection">
        <section className="tool-select">
          <h1>
            <Trans i18nKey="tool-selection.title" t={t} />
          </h1>

          <label>
            <p>
              <Trans i18nKey="tool-selection.prompt" t={t} />
            </p>
            <Dropdown options={optionsWithLabel}>
              {t("tool-selection.select-tool")}
            </Dropdown>
          </label>

          <Popup open={open} onClose={() => setOpen(false)}>
            {t("tool-selection.about-pta.popup-content")}
          </Popup>
        </section>
      </main>
    </BasePage>
  );
};

export default ToolSelection;
