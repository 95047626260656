import {
  FormElement,
  FormValues,
  HybridFormElement,
  ToolConfig,
  Visibility,
} from "../../types";
import extractFormElements from "../validate/extractFormElements";

export const calculateAutocompleteVisibility = (
  toolConfig: UnionToolConfig,
  formValues: FormValues,
  visibility: Visibility,
) => {
  let formElements: HybridFormElement[] = extractFormElements(
    toolConfig.eligibility,
  );
  toolConfig.application.forEach((page) => {
    formElements = [...formElements, ...extractFormElements(page)];
  });

  const autocompletes = formElements.filter(
    (formElement) =>
      (formElement as FormElement).type === "address-autocomplete",
  );

  autocompletes.forEach((autocomplete: HybridFormElement) => {
    const autocompleteFieldNames = (autocomplete as FormElement).field
      ?.addressAutocompleteFieldNames;

    const needToHideAddressFields =
      !formValues[autocomplete.name] || visibility[autocomplete.name] === false;

    if (needToHideAddressFields && autocompleteFieldNames) {
      for (const fieldName of Object.values(autocompleteFieldNames)) {
        visibility[fieldName] = false;
      }
    }
  });

  return visibility;
};
