import { Field, FormElement, FormElementType } from "../../types";

const getFieldsByFormElementType = (
  formElements: FormElement[],
  isValid: (field: Field) => boolean = () => true,
) => {
  const fieldsByFormElementType: Partial<Record<FormElementType, string[]>> =
    {};

  formElements.forEach((element) => {
    if (fieldsByFormElementType[element.type] === undefined) {
      fieldsByFormElementType[element.type] = [];
    }

    if (element.field) {
      const field = element.field;
      if (isValid(field)) {
        fieldsByFormElementType[element.type].push(field.name);
      }
    }
  });
  return fieldsByFormElementType;
};

export default getFieldsByFormElementType;
