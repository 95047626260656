import React, { FC } from "react";
import i18n from "i18next";

import Fetch from "../Fetch";
import ToolSelection from "../ToolSelection";
import { getTools } from "../../api/tools";
import "./ToolSelectionPage.scss";

const ToolSelectionPage: FC = () => {
  return (
    <>
      <Fetch
        component={ToolSelection}
        request={() =>
          getTools()
            .then(({ tools, translations }) => {
              i18n.addResourceBundle("en", "tools", translations);
              return { options: tools };
            })
            .catch((error) => {
              console.error(error);

              return { options: [] };
            })
        }
      />
    </>
  );
};

export default ToolSelectionPage;
