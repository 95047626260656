import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";
import env from "../env";
import "./EnvironmentAlert.scss";

interface EnvironmentAlertProps {
  env: ("demo" | "dev" | "test") & typeof env;
}

const siteUrl = "https://propertytaxaide.aarpfoundation.org";

const EnvironmentAlert: FC<EnvironmentAlertProps> = ({ env }) => {
  const { t } = useTranslation();

  return (
    <aside className="EnvironmentAlert">
      <div
        className={classNames({ "allow-environment-alert": env !== "prod" })}
      >
        <span className="environment-name">{t(`env.${env}`)}</span>
        <span>For testing only. </span>
        <span aria-hidden="true">If you're serving real clients, </span>
        <a
          href={siteUrl}
          className={"alert-link"}
          aria-label="Click here if you're serving real clients"
        >
          click here
        </a>
        .
      </div>
    </aside>
  );
};

export default EnvironmentAlert;
