import { Observable } from "rxjs";

const synchronousAccessToLastObservableValue = <T>(obs: Observable<T>) => {
  let lastValue: T;

  obs.subscribe((value) => (lastValue = value));

  const getValue = () => lastValue;

  return getValue;
};

export default synchronousAccessToLastObservableValue;
