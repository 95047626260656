// Designed to memoize any function call whose arguments can be
// collectively serialized into JSON, including promises
// A promise is cached while in flight
// If the promise is rejected its value is removed from the cache
// The generic function types mean that the signature of the
// memoized function will match that of the original

import { EnsureSerializableArgs } from "../types";

async function clearOnError<T extends keyof any>(
  state: Record<T, any>,
  key: T,
) {
  try {
    await state[key];
  } catch (e) {
    delete state[key];
  }
}

export default function memoize<F extends (...args: any[]) => any>(
  callback: EnsureSerializableArgs<F>,
): (...args: Parameters<F>) => ReturnType<F> {
  const state: Record<string, ReturnType<F>> = {};

  return function (...args) {
    const key = JSON.stringify(args);

    if (!(key in state)) {
      state[key] = callback(...args);
      clearOnError(state, key);
    }

    return state[key];
  };
}
