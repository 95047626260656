const thousandsSeparators = /,/g;

const parseCurrency = (value: string | number | undefined): number => {
  if (typeof value === "number") {
    return value;
  } else {
    return value ? parseFloat(value.replace(thousandsSeparators, "")) : 0;
  }
};

export default parseCurrency;
