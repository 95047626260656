import { appConfig } from "../../../appConfig";

const { authRequired } = appConfig;

const getConsentHeader = () => {
  return {
    dense: true,
    formElements: [
      {
        name: "survey-client-consent-header",
        type: "section-header",
      },
    ],
  };
};

const getConsentTextAndClientName = () => {
  return {
    dense: true,
    formElements: [
      {
        name: "survey-client-consent-text-1",
        type: "static-text",
        config: {
          centerAlign: true,
        },
      },
      {
        name: "survey-1-client-name",
        type: "text",
        field: {
          name: "survey-1-client-name",
        },
      },
      {
        name: "survey-client-consent-text-2",
        type: "static-text",
        config: {
          centerAlign: true,
        },
      },
    ],
  };
};

const getAgreeToShare = () => {
  return {
    formElements: [
      {
        name: "survey-2-agree-to-share",
        type: "checkbox",
        config: {
          bold: "true",
        },
        field: {
          name: "survey-2-agree-to-share",
        },
      },
    ],
  };
};

const getAllowToSendInfo = () => {
  return {
    formElements: [
      {
        name: "survey-3-allow-to-send-info",
        type: "checkbox",
        config: {
          bold: "true",
        },
        field: {
          name: "survey-3-allow-to-send-info",
          optional: true,
        },
      },
    ],
  };
};

const getEmailHeader = () => {
  return {
    formElements: [
      {
        name: "survey-applicant-email-description",
        type: "static-text",
      },
    ],
  };
};

const getEmails = () => {
  return {
    formElements: [
      {
        name: "survey-4-applicant-email",
        type: "email",
        field: {
          name: "survey-4-applicant-email",
          optional: false,
        },
      },
      {
        name: "survey-5-co-applicant-email",
        type: "email",
        field: {
          name: "survey-5-co-applicant-email",
          optional: true,
        },
      },
    ],
  };
};

const userAssignment = {
  name: "survey-7-user-assignment-select",
  type: "user-assignment-select",
  field: {
    name: "survey-7-user-assignment-select",
    optional: false,
  },
};

const getDateAndAssignment = () => {
  const val = {
    formElements: [
      {
        name: "survey-6-client-consent-date",
        type: "todays-date",
        field: {
          name: "survey-6-client-consent-date",
        },
      },
    ],
  };

  if (authRequired) {
    val.formElements.push(userAssignment);
  }

  return val;
};

export const getClientConsentAndAssignmentConfig = () => {
  return {
    pageNumber: 1,
    title: "Survey",
    pageName: "Client Consent",
    formRows: [
      getConsentHeader(),
      getConsentTextAndClientName(),
      getAgreeToShare(),
      getAllowToSendInfo(),
      getEmailHeader(),
      getEmails(),
      getDateAndAssignment(),
    ],
  };
};
