import React, { FC } from "react";

const CaretDown: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.943 9h12.114c.838 0 1.259 1.022.665 1.62l-6.056 6.102a.937.937 0 0 1-1.332 0L5.278 10.62C4.685 10.022 5.105 9 5.943 9Z" />
    </svg>
  );
};

export default CaretDown;
