import { useEffect, useState } from "react";
import { Observable } from "rxjs";

const useObservable = <T>(observable: Observable<T>, initialState?: T) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const subscription = observable.subscribe((value) => setState(value));
    return () => subscription.unsubscribe();
  }, [observable]);

  return state;
};

export default useObservable;
