import getSurveyAnalytics from "../tools/survey/getSurveyAnalytics";
import { combineLatest, merge, Observable } from "rxjs";
import { createHistory, updateHistory } from "../api/history";
import {
  filter,
  map,
  mergeMap,
  scan,
  skip,
  share,
  take,
  withLatestFrom,
} from "rxjs/operators";
import {
  GetHistoryFieldsFunction,
  EligibilityFunction,
  AnalyticsEvent,
  FormValues,
  History,
} from "../types";

type BuildHistoryUpdaterFunction = (
  eventBus: Observable<AnalyticsEvent>,
  formValuesObs: Observable<FormValues>,
  calculateEligibility: EligibilityFunction,
  getHistoryFields: GetHistoryFieldsFunction,
  toolName: string,
) => Observable<History>;

type HistoryObservableFromEventFunction = (
  eventBus: Observable<AnalyticsEvent>,
  formValuesObs: Observable<FormValues>,
  toolName: string,
  calculateEligibility: EligibilityFunction,
  getHistoryFields: GetHistoryFieldsFunction,
) => Observable<History>;

type PersistSessionFromHistoryFunction = (
  historyObs: Observable<History>,
) => Observable<History>;

const buildHistoryUpdater: BuildHistoryUpdaterFunction = (
  eventBus,
  formValuesObs,
  calculateEligibility,
  getHistoryFields,
  toolName,
) => {
  const historyObs = historyObservableFromEvent(
    eventBus,
    formValuesObs,
    toolName,
    calculateEligibility,
    getHistoryFields,
  );

  persistSessionFromHistory(historyObs).subscribe();

  return historyObs;
};

export default buildHistoryUpdater;

export const historyObservableFromEvent: HistoryObservableFromEventFunction = (
  eventBus,
  formValuesObs,
  toolName,
  calculateEligibility,
  getHistoryFields,
) => {
  const now = Date.now();
  return eventBus.pipe(
    filter(({ type }) => type === "ANALYTICS_EVENT"),
    scan((acc, event) => ({ ...acc, ...event.payload }), {} as FormValues),
    withLatestFrom(formValuesObs),
    map(([{ complete, nps }, values]) => {
      return {
        started_at: new Date(now).toISOString(),
        ended_at: new Date().toISOString(),
        eligible: calculateEligibility(values).eligible,
        nps,
        program: toolName,
        complete,
        applicant_data_attributes: getHistoryFields(values, toolName),
        survey_attributes: getSurveyAnalytics(values),
      };
    }),
    share(),
  );
};

export const persistSessionFromHistory: PersistSessionFromHistoryFunction = (
  historyObs,
) => {
  const createHistoryObs = historyObs.pipe(
    take(1),
    mergeMap((history) => {
      return createHistory(history);
    }),
    share(),
  );

  const updateHistoryObs = combineLatest([historyObs.pipe(skip(1))]).pipe(
    mergeMap(([history]) => {
      return updateHistory(history);
    }),
    share(),
  );

  return merge(createHistoryObs, updateHistoryObs);
};
