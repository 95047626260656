import axios from "axios";

import currentToken from "../models/auth/currentToken";
import { appConfig } from "../appConfig";

const api = axios.create();
const { apiBaseUrl } = appConfig;

api.defaults.headers.common["Accept"] = "application/json";

const csrfElement: HTMLMetaElement | null = document.querySelector(
  'meta[name="csrf-token"]',
);
let csrfToken = csrfElement ? csrfElement["content"] : "";

api.defaults.headers.common["X-CSRF-Token"] = csrfToken;
api.defaults.baseURL = apiBaseUrl;

currentToken.subscribe((token) => {
  if (token?.tokenType === "auth0") {
    api.defaults.headers.common["Authorization"] = `Bearer ${token.value}`;
  }
});

export default api;
