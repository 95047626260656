import { Subject } from "rxjs";

import { FormValues, PageType } from "../types";
import { tokenKey, tokenTypeKey } from "./auth/currentToken";

export const persistSessionOnRefresh = (toolName: string) => {
  sessionStorage.setItem("activeTool", toolName);
};

export const hydrateSessionValues = (
  userUpdates: Subject<FormValues>,
  currentTool: string,
  activeTool: string,
) => {
  if (activeTool === currentTool) {
    const sessionValues = JSON.parse(sessionStorage.getItem("values"));
    userUpdates.next(sessionValues);
  }
};

export const persistNextPage = (pageType: PageType, pageNumber: number) => {
  sessionStorage.setItem("pageType", `${pageType}`);
  sessionStorage.setItem("pageNumber", `${pageNumber}`);
};

export const persistLoggedInToken = () => {
  const authToken = sessionStorage.getItem(tokenKey);
  const authTokenType = sessionStorage.getItem(tokenTypeKey);
  sessionStorage.clear();
  if (authToken && authTokenType) {
    sessionStorage.setItem(tokenKey, authToken);
    sessionStorage.setItem(tokenTypeKey, authTokenType);
  }
};
