import React, { FC, useEffect, useState, useCallback } from "react";
import currentToken from "../models/auth/currentToken";
import { useAuth0 } from "@auth0/auth0-react";

import "./InactivityManager.scss";

type Props = {
  callback: () => void;
  children: JSX.Element | JSX.Element[];
  inactivityMinutes?: number;
};

type ActivityEventOption = "click" | "keyDown";

const InactivityManager: FC<Props> = (props: Props) => {
  const inactivityMinutesDefault = 30;
  const oneMinuteMilliseconds = 60000;

  const {
    callback,
    children,
    inactivityMinutes = inactivityMinutesDefault,
  } = props;

  const [activity, setActivity] = useState<string[]>([]);
  const [minutes, setMinutes] = useState(0);

  const { getAccessTokenSilently } = useAuth0();

  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    timeout = setTimeout(() => {
      setMinutes(minutes + 1);
    }, oneMinuteMilliseconds);

    getAccessTokenSilently().then((token) => {
      const oldToken = currentToken.getValue();

      if (token !== oldToken?.value) {
        currentToken.next({
          value: token,
          tokenType: "auth0",
        });
      }
    });

    const timeIsUp = minutes === inactivityMinutes;
    const noUserActivity = activity.length === 0;

    if (timeIsUp) {
      if (noUserActivity) {
        callback();
      } else {
        reset();
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [minutes]);

  const reset = () => {
    clearTimeout(timeout);
    setMinutes(0);
    setActivity([]);
  };

  const addActivity = useCallback(
    (activityEvent: ActivityEventOption) => {
      setActivity(activity.concat([activityEvent]));
    },
    [setActivity],
  );

  const onClick = useCallback(() => addActivity("click"), [addActivity]);
  const onKeyDown = useCallback(() => addActivity("keyDown"), [addActivity]);

  return (
    <div className="InactivityManager" onClick={onClick} onKeyDown={onKeyDown}>
      {children}
    </div>
  );
};

export default InactivityManager;
