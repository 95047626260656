import parseCurrency from "./parseCurrency";

const roundNearestDollarFormat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatCurrencyRoundToNearestDollar = (
  str: string | undefined,
): string => {
  return str ? roundNearestDollarFormat.format(parseCurrency(str)) : "";
};
