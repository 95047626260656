import getFieldsByFormElementType from "./getFieldsByFormElementType";
import getVisibleFields from "./getVisibleFields";
import validateDates from "./validateDates";
import validateEmails from "./validateEmails";
import validateMaxLength from "./validateMaxLength";
import validateRequired from "./validateRequired";
import validateSsn from "./validateSsn";
import validateTelephone from "./validateTelephone";
import validateZipcode from "./validateZipcode";
import {
  FormValues,
  ToolConfigPage,
  ConditionalVisibilityFunction,
} from "../../types";
import validateSsnLast4 from "./validateSsnLast4";
import extractFormElements from "./extractFormElements";
import validateMatchingFields from "./validateMatchingFields";
import validateMask from "./validateMaskInput";
import validateNegativeCurrency from "./validateNegativeCurrency";
import { validateSurveyBirthYear }  from "./validateBirthYear";

import { CustomValidation } from "../../types";

const customValidationNoOp: CustomValidation = (_: FormValues) => ({});

function buildValidate(
  calculateVisibility: ConditionalVisibilityFunction,
  page: ToolConfigPage,
  customValidation: CustomValidation | undefined,
) {
  return (formValues: FormValues) => {
    const formElements = extractFormElements(page);
    const visibleFields = getVisibleFields(
      formValues,
      formElements,
      calculateVisibility,
    );
    const visibleFieldsSet = new Set(visibleFields);
    const fieldsByFormElementType = getFieldsByFormElementType(
      formElements,
      (field) => visibleFieldsSet.has(field),
    );
    const customValidator = customValidation || customValidationNoOp;
    const validation = {
      ...customValidator(formValues),
      ...validateMaxLength(formValues, visibleFields),
      ...validateDates(formValues, fieldsByFormElementType["date"] || []),
      ...validateDates(
        formValues,
        fieldsByFormElementType["todays-date"] || [],
      ),
      ...validateEmails(formValues, fieldsByFormElementType["email"] || []),
      ...validateSsn(formValues, fieldsByFormElementType["ssn"] || []),
      ...validateSsnLast4(
        formValues,
        fieldsByFormElementType["ssn-last-4"] || [],
      ),
      ...validateTelephone(
        formValues,
        fieldsByFormElementType["telephone"] || [],
      ),
      ...validateZipcode(formValues, fieldsByFormElementType["zipcode"] || []),
      ...validateRequired(formValues, visibleFields),
      ...validateMatchingFields(formValues, visibleFields),
      ...validateMask(
        formValues,
        fieldsByFormElementType["id-input"] || [],
        formElements,
      ),
      ...validateNegativeCurrency(formValues, visibleFields),
      ...validateSurveyBirthYear(
        formValues,
        fieldsByFormElementType["survey-text"] || [],
        formElements,
      ),
    };

    return validation;
  };
}

export default buildValidate;
