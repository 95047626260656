import { Visibility } from "../../types";
import { SurveyInputValues } from "./types";

const calculateSurveyVisibility = (values: SurveyInputValues) => {
  const visibility: Visibility = {};

  const birthYearInput = "survey-questionnaire-question-3-birth-year";
  const genderPreferNotToanswer = "survey-gender-prefer-not-to-answer";
  const genderQuestionnaire = "survey-questionnaire-question-4";
  const genderSelfDescribe = "survey-gender-self-describe";
  const genderShowSelfDescribe = "survey-gender-show-self-describe";
  const household1 = "survey-household-size-1";
  const household2 = "survey-household-size-2";
  const household3 = "survey-household-size-3";
  const household4OrMore = "survey-household-size-4-plus";
  const householdQuestionnaire = "survey-questionnaire-question-7";
  const racePreferNotToAnswer = "survey-race-prefer-not-to-answer";
  const raceQuestionnaire = "survey-questionnaire-question-5";
  const surveyRaceSelfDescription = "survey-race-self-description";
  const surveyRacePreferToSelfDescribe = "survey-race-prefer-to-self-describe";

  visibility[surveyRaceSelfDescription] = false;
  visibility[genderSelfDescribe] = false;

  const allowToSendInfo = !!values["survey-3-allow-to-send-info"];

  visibility["survey-4-applicant-email"] = allowToSendInfo;
  visibility["survey-5-co-applicant-email"] = allowToSendInfo;
  visibility["survey-applicant-email-description"] = allowToSendInfo;

  visibility[birthYearInput] = true;
  if (
    !!values[genderQuestionnaire] &&
    values[genderQuestionnaire].includes(genderShowSelfDescribe)
  ) {
    visibility[genderSelfDescribe] = true;
  }
  if (
    !!values[genderQuestionnaire] &&
    values[genderQuestionnaire].includes(genderPreferNotToanswer)
  ) {
    visibility[genderSelfDescribe] = false;
  }
  if (
    !!values[raceQuestionnaire] &&
    values[raceQuestionnaire].includes(surveyRacePreferToSelfDescribe)
  ) {
    visibility[surveyRaceSelfDescription] = true;
  }
  if (
    !!values[raceQuestionnaire] &&
    values[raceQuestionnaire].includes(racePreferNotToAnswer)
  ) {
    visibility[surveyRaceSelfDescription] = false;
  }

  visibility["survey-questionnaire-question-household-1"] =
    !!values[householdQuestionnaire] &&
    values[householdQuestionnaire] === household1;
  visibility["survey-questionnaire-question-household-2"] =
    !!values[householdQuestionnaire] &&
    values[householdQuestionnaire] === household2;
  visibility["survey-questionnaire-question-household-3"] =
    !!values[householdQuestionnaire] &&
    values[householdQuestionnaire] === household3;
  visibility["survey-questionnaire-question-household-4-or-more"] =
    !!values[householdQuestionnaire] &&
    values[householdQuestionnaire] === household4OrMore;

  return visibility;
};

export default calculateSurveyVisibility;
