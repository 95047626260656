import { Fragment } from "react";

import { AuthProvider } from "./components/AuthProvider";

type AppConfig = {
  apiBaseUrl: string;
  authRequired: boolean;
  authWrapper: typeof Fragment | typeof AuthProvider;
  public: boolean;
};

const isCypress = process.env.CYPRESS == "1";
const isPublic = window.location.hostname === process.env.PUBLIC_HOSTNAME;
const noAuth = Fragment;

export const appConfig: AppConfig = isPublic
  ? {
      apiBaseUrl: "/public_api/",
      authRequired: false,
      authWrapper: noAuth,
      public: true,
    }
  : isCypress
  ? {
      apiBaseUrl: "/api/",
      authRequired: false,
      authWrapper: noAuth,
      public: false,
    }
  : {
      apiBaseUrl: "/api/",
      authRequired: true,
      authWrapper: AuthProvider,
      public: false,
    };
