import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import useTimer from "../../models/utils/useTimer";

import "./LoginPage.scss";
import AlliePage from "./AlliePage";
import { Auth0LoginButton } from "../buttons/Auth0LoginButton";

const LoginPage: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();
  const isVisible = useTimer(350);

  return (
    <div data-testid="login_page" className="login_wrapper">
      {!isVisible ? null : (
        <AlliePage>
          <main className="Login">
            <div className="auth0-button-container">
              <h1>
                <Trans i18nKey="login.title" t={t} />
              </h1>
              {isAuthenticated ? null : <Auth0LoginButton />}
            </div>
          </main>
        </AlliePage>
      )}
    </div>
  );
};

export default LoginPage;
