import i18next from "i18next";
import { useRef } from "react";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { filter, map, shareReplay, startWith } from "rxjs/operators";

import surveyTranslations from "../tools/survey/en.ts";
import getClientConsentPdfFields from "../tools/survey/getClientConsentPdfFields";
import { getSurveyConfig } from "../tools/survey/getSurveyConfig";
import {
  AnalyticsEvent,
  FormValues,
  ToolConfigPage,
  ToolDescription,
} from "../types";
import buildCalculatedUpdatesDecorator from "./buildCalculatedUpdatesDecorator";
import buildCalculatedValues from "./buildCalculatedValues";
import buildFormValues from "./buildFormValues";
import buildHistoryUpdater from "./buildHistoryUpdater";
import buildPageCompletion from "./buildPageCompletion";
import buildToolConfig from "./buildToolConfig";
import buildUserUpdatesDecorator from "./buildUserUpdatesDecorator";
import { hydrateNextPageToComplete } from "./hydrateNextPageToComplete.ts";
import {
  hydrateSessionValues,
  persistNextPage,
  persistSessionOnRefresh,
} from "./persistSessionValues";
import { restartTool } from "./restartTool";
import "./subscriptions/subscriptions";
import toolDescriptionManager from "./toolDescriptionManager";
import synchronousAccessToLastObservableValue from "./utils/synchronousAccessToLastObservableValue";
import useObservable from "./utils/useObservable";
import buildValidate from "./validate";
import buildGetElementVisibilityObservable from "./visibility/buildGetElementVisibilityObservable";
import generateVisibilityFunction from "./visibility/generateVisibilityFunction";

const expectedProps: (keyof ToolDescription)[] = [
  "calculateEligibility",
  "calculatedFields",
  "conditionalVisibility",
  "toolConfig",
  "translations",
];

export function toolDescriptionHydrator({
  calculateEligibility,
  calculatedFields,
  conditionalVisibility,
  customValidation,
  getHistoryFields,
  getPdfFields,
  toolConfig,
  toolConfigFilter,
  toolName,
  translations,
  testCases,
  pending,
}: ToolDescription) {
  if (pending) {
    return { toolName, pending };
  }

  const userUpdates = new Subject<FormValues>();

  const getVisibileFields = generateVisibilityFunction(
    conditionalVisibility,
    toolConfig,
  );

  const calculatedValues = buildCalculatedValues(
    calculatedFields,
    getVisibileFields,
    userUpdates,
  );

  const userUpdatesDecorator = buildUserUpdatesDecorator(userUpdates);

  const calculatedUpdatesDecorator =
    buildCalculatedUpdatesDecorator(calculatedValues);

  const formValuesObs = buildFormValues(userUpdates, calculatedValues);

  const getFormValues = synchronousAccessToLastObservableValue(formValuesObs);

  const toolConfigBehaviorSubject = new BehaviorSubject(toolConfig);

  const toolConfigObs = buildToolConfig(
    formValuesObs,
    toolConfigBehaviorSubject,
    toolConfigFilter,
  );

  const getToolConfig = synchronousAccessToLastObservableValue(toolConfigObs);

  const getElementVisibilityObservable = buildGetElementVisibilityObservable(
    getVisibileFields,
    formValuesObs,
  );

  formValuesObs.subscribe((values) =>
    sessionStorage.setItem("values", JSON.stringify(values)),
  );

  const eventBus = new Subject<AnalyticsEvent>();

  buildHistoryUpdater(
    eventBus,
    formValuesObs,
    calculateEligibility,
    getHistoryFields,
    toolName,
  );

  const validateEligibility = buildValidate(
    getVisibileFields,
    toolConfigBehaviorSubject.getValue().eligibility,
    customValidation,
  );

  const validateApplicationPages = (pages: ToolConfigPage[]) =>
    pages.map((page) =>
      buildValidate(getVisibileFields, page, customValidation),
    );

  const surveyConfig = getSurveyConfig();

  const validateSurveyPages = surveyConfig.map((page) =>
    buildValidate(getVisibileFields, page, customValidation),
  );

  const useElementVisibility = (element: string) => {
    const observable = useRef(getElementVisibilityObservable(element));
    const currentRef = useObservable(observable.current);

    return currentRef;
  };

  i18next.addResourceBundle("en", "current-tool", translations);
  i18next.addResourceBundle("en", "survey", surveyTranslations);

  const activeTool = sessionStorage.getItem("activeTool");

  const pageToComplete = hydrateNextPageToComplete(toolName, activeTool);
  const pageCompletion = buildPageCompletion(pageToComplete);

  const getAllPdfFields = (formValues: FormValues) => {
    return [...getPdfFields(formValues), getClientConsentPdfFields(formValues)];
  };

  if (activeTool) {
    hydrateSessionValues(userUpdates, toolName, activeTool);
  }
  persistSessionOnRefresh(toolName);

  pageToComplete.subscribe((pageRoute) => {
    persistNextPage(pageRoute.pageType, pageRoute.pageNumber);
  });

  const isLegacyTool = toolConfigBehaviorSubject
    ?.getValue()
    ?.hasOwnProperty("eligibility");

  const alwaysShowPdfOptions = toolName === "demographics";

  return {
    alwaysShowPdfOptions,
    calculatedUpdatesDecorator,
    calculateEligibility,
    eventBus: {
      publish(event: AnalyticsEvent) {
        eventBus.next(event);
      },
    },
    getAllPdfFields,
    getFormValues,
    getToolConfig,
    isLegacyTool,
    pageCompletion,
    surveyConfig,
    testCases,
    toolConfig,
    toolConfigObs,
    toolName,
    translations,
    useElementVisibility,
    userUpdatesDecorator,
    validateApplicationPages,
    validateEligibility,
    validateSurveyPages,
    pending,
  };
}

export const buildCurrentTool = (
  toolDescriptionManager: Observable<Partial<ToolDescription>>,
) => {
  const restartableTool = combineLatest([
    restartTool.pipe(startWith({ type: "RESTART" })),
    toolDescriptionManager,
  ]).pipe(
    filter(([e, _toolDescription]) => {
      return e.type === "RESTART";
    }),
    filter(
      ([_e, toolDescription]) =>
        toolDescription.pending ||
        expectedProps.every((prop) => !!toolDescription[prop]),
    ),
    map(([_e, toolDescription]) => toolDescription),
  );

  return restartableTool.pipe(map(toolDescriptionHydrator), shareReplay(1));
};

const currentTool = buildCurrentTool(toolDescriptionManager);

export default currentTool;
