import api from "./api";
import { History } from "../types";

const APPLICANT_SESSION_ID = "applicant_session_id";

export const createHistory = async (
  applicantSession: History,
): Promise<History> => {
  const existingSessionId = sessionStorage.getItem(APPLICANT_SESSION_ID);

  if (existingSessionId) {
    return updateHistory(applicantSession);
  } else {
    const response = await api.post("/applicant_sessions", {
      applicant_session: applicantSession,
    });
    sessionStorage.setItem(APPLICANT_SESSION_ID, response.data.id);

    return response.data;
  }
};

export const updateHistory = async (
  applicantSession: History,
): Promise<History> => {
  const existingSessionId = sessionStorage.getItem(APPLICANT_SESSION_ID);

  if (existingSessionId) {
    const response = await api.patch(
      `/applicant_sessions/${existingSessionId}`,
      {
        applicant_session: applicantSession,
      },
    );

    return response.data;
  } else {
    return createHistory(applicantSession);
  }
};
