import { Survey } from "../../types";
import { SurveyInputValues } from "./types";
import { INCOME_THRESHOLDS } from "./constants";

const {
  HOUSEHOLD_SIZE_1,
  HOUSEHOLD_SIZE_2,
  HOUSEHOLD_SIZE_3,
  HOUSEHOLD_SIZE_4,
} = INCOME_THRESHOLDS;

const householdSizeMappings: Record<string, number> = {
  "survey-household-size-4-plus": 4,
  "survey-household-size-3": 3,
  "survey-household-size-2": 2,
  "survey-household-size-1": 1,
};

const setIncomeThreshold = (values: SurveyInputValues): number | null => {
  switch (values["survey-questionnaire-question-7"]) {
    case "survey-household-size-1":
      return HOUSEHOLD_SIZE_1;
    case "survey-household-size-2":
      return HOUSEHOLD_SIZE_2;
    case "survey-household-size-3":
      return HOUSEHOLD_SIZE_3;
    case "survey-household-size-4-plus":
      return HOUSEHOLD_SIZE_4;
    default:
      return null;
  }
};

const isAboveIncomeThreshold = (values: SurveyInputValues): boolean | null => {
  if (!!values["survey-questionnaire-question-household-1"]) {
    if (
      values["survey-questionnaire-question-household-1"] ===
      "survey-household-1-yes"
    ) {
      return true;
    } else if (
      values["survey-questionnaire-question-household-1"] ===
      "survey-household-1-no"
    ) {
      return false;
    }
  }

  if (!!values["survey-questionnaire-question-household-2"]) {
    if (
      values["survey-questionnaire-question-household-2"] ===
      "survey-household-2-yes"
    ) {
      return true;
    } else if (
      values["survey-questionnaire-question-household-2"] ===
      "survey-household-2-no"
    ) {
      return false;
    }
  }

  if (!!values["survey-questionnaire-question-household-3"]) {
    if (
      values["survey-questionnaire-question-household-3"] ===
      "survey-household-3-yes"
    ) {
      return true;
    } else if (
      values["survey-questionnaire-question-household-3"] ===
      "survey-household-3-no"
    ) {
      return false;
    }
  }

  if (!!values["survey-questionnaire-question-household-4-or-more"]) {
    if (
      values["survey-questionnaire-question-household-4-or-more"] ===
      "survey-household-4-or-more-yes"
    ) {
      return true;
    } else if (
      values["survey-questionnaire-question-household-4-or-more"] ===
      "survey-household-4-or-more-no"
    ) {
      return false;
    }
  }

  return null;
};

const isAboveIncomeThresholdNoAnswer = (
  values: SurveyInputValues,
): boolean | null => {
  const isIncomeNoAnswerForSizeOne =
    !!values["survey-questionnaire-question-household-1"] &&
    values["survey-questionnaire-question-household-1"] ===
      "survey-household-1-prefer-not-to-answer";

  const isIncomeNoAnswerForSizeTwo =
    !!values["survey-questionnaire-question-household-2"] &&
    values["survey-questionnaire-question-household-2"] ===
      "survey-household-2-prefer-not-to-answer";

  const isIncomeNoAnswerForSizeThree =
    !!values["survey-questionnaire-question-household-3"] &&
    values["survey-questionnaire-question-household-3"] ===
      "survey-household-3-prefer-not-to-answer";

  const isIncomeNoAnswerForSizeFourPlus =
    !!values["survey-questionnaire-question-household-4-or-more"] &&
    values["survey-questionnaire-question-household-4-or-more"] ===
      "survey-household-4-or-more-prefer-not-to-answer";

  return (
    isIncomeNoAnswerForSizeOne ||
    isIncomeNoAnswerForSizeTwo ||
    isIncomeNoAnswerForSizeThree ||
    isIncomeNoAnswerForSizeFourPlus
  );
};

const isOverFifty = (values: SurveyInputValues): boolean | null => {
  const value = values["survey-over-fifty"];

  if (value === "yes") {
    return true;
  } else if (value === "no") {
    return false;
  } else if (value == null) {
    return null;
  } else {
    throw new Error(`Invalid value for is_over_50: ${value}`);
  }
};

const isLgbtq = (values: SurveyInputValues): boolean | null => {
  if (values["survey-questionnaire-question-lgbtq"] === "survey-lgbtq-yes") {
    return true;
  } else if (
    values["survey-questionnaire-question-lgbtq"] === "survey-lgbtq-no"
  ) {
    return false;
  } else {
    return null;
  }
};

const isHispanic = (values: SurveyInputValues): boolean | null => {
  if (
    values["survey-questionnaire-question-6"] === "survey-hispanic-descent-yes"
  ) {
    return true;
  } else if (
    values["survey-questionnaire-question-6"] === "survey-hispanic-descent-no"
  ) {
    return false;
  } else {
    return null;
  }
};

const isDisabled = (values: SurveyInputValues): boolean | null => {
  if (values["survey-questionnaire-disability"] === "survey-disability-yes") {
    return true;
  } else if (
    values["survey-questionnaire-disability"] === "survey-disability-no"
  ) {
    return false;
  } else {
    return null;
  }
};

const getSurveyAnalytics = (values: SurveyInputValues): Survey => {
  const assignmentSourceId = "survey-7-user-assignment-select";
  const birthYearPreferNotToAnswer =
    "survey-questionnaire-question-3-birth-year-prefer-not-to-answer";
  const disabilityQuestionnaire = "survey-questionnaire-disability";
  const genderQuestionnaire = "survey-questionnaire-question-4";
  const hispanicQuestionnaire = "survey-questionnaire-question-6";
  const lgbtqQuestionnaire = "survey-questionnaire-question-lgbtq";
  const racePreferNotToAnswer = "survey-race-prefer-not-to-answer";
  const raceQuestionnaire = "survey-questionnaire-question-5";

  return {
    above_income_threshold: isAboveIncomeThreshold(values),
    above_income_threshold_noanswer: isAboveIncomeThresholdNoAnswer(values),
    assignment_source_id: values[assignmentSourceId] || null,
    birth_year:
      parseInt(values["survey-questionnaire-question-3-birth-year"] || "") ||
      null,
    client_consent: !!values["survey-2-agree-to-share"],
    disability_or_chronic_condition: isDisabled(values),
    disability_or_chronic_condition_noanswer:
      !!values[disabilityQuestionnaire] &&
      values[disabilityQuestionnaire].includes(
        "survey-disability-prefer-not-to-answer",
      ),
    gender_female:
      !!values[genderQuestionnaire] &&
      values[genderQuestionnaire].includes("survey-gender-female"),
    gender_male:
      !!values[genderQuestionnaire] &&
      values[genderQuestionnaire].includes("survey-gender-male"),
    gender_noanswer:
      !!values[genderQuestionnaire] &&
      values[genderQuestionnaire].includes(
        "survey-gender-prefer-not-to-answer",
      ),
    gender_non_binary:
      !!values[genderQuestionnaire] &&
      values[genderQuestionnaire].includes("survey-gender-non-binary"),
    gender_self_describe: values["survey-gender-self-describe"] || null,
    hispanic: isHispanic(values),
    hispanic_noanswer:
      !!values[hispanicQuestionnaire] &&
      values[hispanicQuestionnaire].includes(
        "survey-hispanic-descent-prefer-not-to-answer",
      ),
    household_size_int:
      householdSizeMappings[
        values["survey-questionnaire-question-7"] as string
      ] || null,
    income_threshold: setIncomeThreshold(values),
    over_50_years_old: isOverFifty(values),
    lgbtq: isLgbtq(values),
    lgbtq_noanswer:
      !!values[lgbtqQuestionnaire] &&
      values[lgbtqQuestionnaire].includes("survey-lgbtq-prefer-not-to-answer"),
    organization_type: values["survey-7-user-assignment-select"] || null,
    race_asian:
      !!values[raceQuestionnaire] &&
      values[raceQuestionnaire].includes("survey-race-asian"),
    race_black:
      !!values[raceQuestionnaire] &&
      values[raceQuestionnaire].includes("survey-race-black-afican-american"),
    race_hawaiian:
      !!values[raceQuestionnaire] &&
      values[raceQuestionnaire].includes(
        "survey-race-native-hawaiian-or-pacific-islander",
      ),
    race_multi:
      !!values[raceQuestionnaire] &&
      values[raceQuestionnaire].includes("survey-race-multi-racial"),
    race_native:
      !!values[raceQuestionnaire] &&
      values[raceQuestionnaire].includes(
        "survey-race-american-indian-alaska-native",
      ),
    race_noanswer:
      !!values[raceQuestionnaire] &&
      values[raceQuestionnaire].includes(racePreferNotToAnswer),
    race_other: values["survey-race-self-description"] || null,
    race_white:
      !!values[raceQuestionnaire] &&
      values[raceQuestionnaire].includes("survey-race-white"),
    yearborn: values["survey-questionnaire-question-3-birth-year"] || null,
    yearborn_noanswer: false,
  };
};

export default getSurveyAnalytics;
