import { BooleanFunc, MetaElement } from "./types";

const PROD = "prod";
const DEMO = "demo";

const el: MetaElement | null = document.querySelector('meta[name="env"]');
const env = el?.content;

if (!env) {
  throw new Error("env not defined");
}

export const isEnvProd: BooleanFunc = () => env === (PROD || DEMO);

export default env;
