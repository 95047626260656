import {
  AddressAutocompleteFieldNames,
  FormElement,
  FormRow,
} from "../../../../src/types";

export const getAddressFormElements = (
  addressFieldNames: AddressAutocompleteFieldNames,
  readOnly?: boolean,
  allowCanadianFields?: boolean,
): FormRow[] => {
  const useCanadianProvincesAndPostalCodes = !readOnly && allowCanadianFields;

  const stateSelectType = useCanadianProvincesAndPostalCodes
    ? "state-province-select"
    : "state-select";

  const postalCodeFieldType = useCanadianProvincesAndPostalCodes
    ? "text"
    : "zipcode";

  let bottomRow: FormElement[] = [
    {
      name: addressFieldNames.zipcode,
      type: postalCodeFieldType,
      field: {
        name: addressFieldNames.zipcode,
        readOnly: readOnly,
        grayedOut: readOnly,
        maxLength: 10,
      },
    },
  ];

  if (addressFieldNames.county) {
    bottomRow.push({
      name: addressFieldNames.county,
      type: "text",
      field: {
        name: addressFieldNames.county,
        readOnly: readOnly,
        grayedOut: readOnly,
      },
    });
  }

  const formRows = [
    {
      formElements: [
        {
          name: addressFieldNames.street_line,
          type: "text",
          field: {
            name: addressFieldNames.street_line,
            size: "expanded",
            maxLength: 70,
            readOnly: readOnly,
            grayedOut: readOnly,
          },
        },
      ],
    },
    {
      formElements: [
        {
          name: addressFieldNames.secondary,
          type: "text",
          field: {
            name: addressFieldNames.secondary,
            size: "expanded",
            maxLength: 70,
            optional: true,
          },
        },
      ],
    },
    {
      formElements: [
        {
          name: addressFieldNames.city,
          type: "text",
          field: {
            name: addressFieldNames.city,
            maxLength: 30,
            readOnly: readOnly,
            grayedOut: readOnly,
          },
        },
        {
          name: addressFieldNames.state,
          type: stateSelectType,
          field: {
            name: addressFieldNames.state,
            readOnly: readOnly,
            grayedOut: readOnly,
          },
        },
      ],
    },
    {
      formElements: bottomRow,
    },
  ];

  return formRows;
};
